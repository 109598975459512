import { ReactElement } from "react";
import { MonitoringType } from "./monitoring-type";
import Constants from "style/constants";
import {
  Battery1BarRounded,
  CloseRounded,
  CloudOffOutlined,
  CloudOutlined,
  WarningAmberRounded,
} from "@mui/icons-material";
import { Tooltip, Zoom } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IProps {
  type: MonitoringType;
  withTooltip: boolean;
  visible: boolean;
  size: number;
}

function MonitoringIcon(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("monitoring");

  const getTooltipText = () => {
    switch (props.type) {
      case MonitoringType.Offline:
        return t("messages.offline");
      case MonitoringType.Online:
        return t("messages.online");
      case MonitoringType.BatteryCritical:
        return t("messages.batteryCritical");
      case MonitoringType.BatteryLow:
        return t("messages.batteryLow");
      case MonitoringType.OutDated:
        return t("messages.outDated");
      case MonitoringType.ExternalSystemOffline:
        return t("messages.externalSystemOffline");
      case MonitoringType.ExternalSystemOnline:
        return t("messages.externalSystemOnline");
      case MonitoringType.NotMonitored:
        return t("messages.notMonitored");
      case MonitoringType.PassiveExternalSystemOffline:
        return t("messages.passiveExternalSystemOffline");
      case MonitoringType.ExternalSystemServiceOffline:
        return t("messages.externalSystemServiceOffline");
    }
  };

  const getSxProperty = (color: string) => {
    return {
      color: props.visible ? color : "transparent",
      width: props.size,
      height: props.size,
    };
  };

  const getIcon = (): JSX.Element => {
    switch (props.type) {
      case MonitoringType.BatteryCritical:
        return (
          <Battery1BarRounded sx={getSxProperty(Constants.Colors.error)} />
        );
      case MonitoringType.BatteryLow:
        return (
          <Battery1BarRounded
            sx={getSxProperty(Constants.Colors.brightOrange)}
          />
        );
      case MonitoringType.OutDated:
        return (
          <WarningAmberRounded sx={getSxProperty(Constants.Colors.error)} />
        );
      case MonitoringType.ExternalSystemOffline:
      case MonitoringType.Offline:
        return <CloudOffOutlined sx={getSxProperty(Constants.Colors.error)} />;
      case MonitoringType.Online:
      case MonitoringType.ExternalSystemOnline:
        return <CloudOutlined sx={getSxProperty(Constants.Colors.success)} />;
      case MonitoringType.NotMonitored:
        return (
          <CloseRounded sx={getSxProperty(Constants.Colors.onSurfaceVariant)} />
        );
      case MonitoringType.PassiveExternalSystemOffline:
      case MonitoringType.ExternalSystemServiceOffline:
        return (
          <CloudOffOutlined sx={getSxProperty(Constants.Colors.brightOrange)} />
        );
      default:
        return <></>;
    }
  };

  return props.withTooltip && props.visible ? (
    <Tooltip title={getTooltipText()} followCursor TransitionComponent={Zoom}>
      {getIcon()}
    </Tooltip>
  ) : (
    getIcon()
  );
}

export default MonitoringIcon;
