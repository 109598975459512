import { RefreshRounded } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { IconButton, Typography } from "@mui/material";
import MultiValueFilterChip from "components/multi-value-filter-chip/multi-value-filter-chip.component";
import FilterChipOrganisationUnit from "components/organisation-unit-filter-chip/filter-chip-organisation-unit.component";
import SearchBar from "components/search/searchbar";
import SelectedOrganisationTreeNode from "features/organisation/domain/models/selected-organisation-tree-node";
import { OrganisationUnitTreeProvider } from "features/organisation/providers/organisation-unit-tree-provider";
import KeyValuePair from "models/key-value-pair";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";
import { useExternalSystemsContextProvider } from "../context/external-systems-provider";
import { FilterValueType } from "../domain/models/external-system-filter-value";
import { ExternalSystemType } from "../domain/models/external-system-type";
import ExternalSystemMonitoringFilter from "./components/external-system-monitoring-filter";
import "./external-system-filters.scss";

export default function ExternalSystemFilters(): ReactElement {
  const { t } = useTranslation("externalSystem");

  const { refresh, externalSystemFilterHook } =
    useExternalSystemsContextProvider();

  const getTypeFilterValues = (): KeyValuePair[] =>
    Object.entries(ExternalSystemType)
      .filter(([_, value]) => value !== ExternalSystemType.None)
      .map(([key, value]) => ({
        key,
        value: t(`filterValues.type.${value}`),
      }));

  return (
    <div className="external-system-filters-container">
      <div className="top-row">
        <div className="external-system-filters">
          <SearchBar
            onSearchValueChanged={externalSystemFilterHook.submitSearch}
            value={externalSystemFilterHook.searchBarValue}
          />
          <FilterListIcon />
          <MultiValueFilterChip
            options={getTypeFilterValues()}
            onOptionsSelected={(keys: string[]) =>
              externalSystemFilterHook.selectOption(FilterValueType.Type, keys)
            }
            placeHolder={t("filters.type")}
            selectedOptions={getTypeFilterValues().filter((x) =>
              externalSystemFilterHook.activeFilters
                .find((x) => x.filterValueType === FilterValueType.Type)
                ?.values.includes(x.key),
            )}
          />
          <OrganisationUnitTreeProvider.All>
            <FilterChipOrganisationUnit
              onOptionsSelected={(keys: SelectedOrganisationTreeNode[]) => {
                externalSystemFilterHook.changeSelectedOrganisationUnits(keys);
                externalSystemFilterHook.selectOption(
                  FilterValueType.OrganisationUnit,
                  keys.map((x) => x.id),
                );
              }}
              selectedOptions={
                externalSystemFilterHook.selectedOrganisationUnits
              }
              placeHolder={t("filters.organisationUnits")}
            />
          </OrganisationUnitTreeProvider.All>

          <div className="clear-filters">
            <Typography
              variant="body1"
              color={Constants.Colors.primary}
              onClick={externalSystemFilterHook.clearFilters}
            >
              {t("filters.clearFilters")}
            </Typography>
          </div>
        </div>

        {refresh && (
          <IconButton className="refresh-button" onClick={refresh}>
            <RefreshRounded />
          </IconButton>
        )}
      </div>

      {externalSystemFilterHook.monitorFiltersIsSuccess &&
        externalSystemFilterHook.monitorFilters.length > 0 && (
          <div className="monitoring-filters">
            <ExternalSystemMonitoringFilter.Offline />
            <ExternalSystemMonitoringFilter.PassiveOffline />
            <ExternalSystemMonitoringFilter.ServiceOffline />
            <ExternalSystemMonitoringFilter.Online />
          </div>
        )}
    </div>
  );
}
