import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeviceDetailsHook } from "features/device/device-details/hooks";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  Controller,
  useFormContext,
  useController,
  FieldErrors,
} from "react-hook-form";
import { DeviceCommandType } from "features/device/models/device-command-type";
import "./device-command-details-popup-settings.component.scss";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface IProps {
  index: number;
  commandType: DeviceCommandType;
  label: string;
  icon: ReactElement;
}

function DeviceCommandDetailsPopupSettings(
  props: Readonly<IProps>,
): ReactElement {
  const { t } = useTranslation("deviceDetails");

  const { currentViewingMode, getDeviceCommand } = useDeviceDetailsHook();

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  const formattedCommandType = props.commandType.toLowerCase();
  const deviceCommand = getDeviceCommand(props.commandType);
  const actionField = useController({
    name: `commands[${props.index}].action`,
  });

  const [showPassword, setShowPassword] = useState(false);

  function getDottedPassword(): string {
    return "*".repeat(10);
  }

  function isValueSet(credentialValue: string): boolean | string {
    let actionValue = actionField.field.value;

    if (
      !isActionValueSet(actionValue) ||
      isCredentialSet(actionValue, credentialValue)
    ) {
      return true;
    }

    return t("info.requiredHelperText");
  }

  function isActionValueSet(value: string | undefined): boolean {
    return value !== undefined && value?.trim().length > 0;
  }

  function isCredentialSet(
    actionValue: string,
    credentialValue: string,
  ): boolean {
    return (
      isActionValueSet(actionValue) &&
      credentialValue !== undefined &&
      credentialValue?.trim().length > 0
    );
  }

  function isUsernameInErrorState(): boolean {
    let commandErrors = getCommandErrors();
    return commandErrors ? !!commandErrors.username : false;
  }

  function isPasswordInErrorState(): boolean {
    let commandErrors = getCommandErrors();
    return commandErrors ? !!commandErrors.password : false;
  }

  function getUsernameErrorMessage(): string {
    let commandErrors = getCommandErrors();
    return commandErrors ? commandErrors.username?.message : undefined;
  }

  function getPasswordErrorMessage(): string {
    let commandErrors = getCommandErrors();
    return commandErrors ? commandErrors.password?.message : undefined;
  }

  function getCommandErrors(): any {
    let commandsArray = errors.commands as FieldErrors;
    return commandsArray !== undefined
      ? (commandsArray[props.index] as any)
      : undefined;
  }

  const registerOptions = {
    username: {
      validate: isValueSet,
    },
    password: {
      validate: isValueSet,
    },
  };

  return (
    <div className="client-info-container full-width">
      <div className="client-info-item full-width">
        {(currentViewingMode === "creation" ||
          currentViewingMode === "editing") && (
          <>
            <div className="information-part-container margin-top-5px full-width">
              <div className="information-part-container-header">
                {props.icon}
                <Typography variant="h5">{props.label}</Typography>
              </div>

              <input
                type="hidden"
                {...register(`commands[${props.index}].id`)}
                value={deviceCommand.id}
              />
              <input
                type="hidden"
                {...register(`commands[${props.index}].type`)}
                value={deviceCommand.type}
              />

              <Controller
                name={`commands[${props.index}].action`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    inputProps={{
                      "data-testid": `commands[${props.index}].action`,
                    }}
                    id={`${formattedCommandType}ActionId`}
                    label={t(`info.${formattedCommandType}ActionLabel`)}
                    variant="outlined"
                    className="form-field full-width"
                  />
                )}
              />
            </div>
            {(formattedCommandType === "opendoor" ||
              formattedCommandType === "video" ||
              formattedCommandType === "image") && (
              <>
                <div className="information-part-container credentials">
                  <Controller
                    name={`commands[${props.index}].username`}
                    control={control}
                    rules={registerOptions.username}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        inputProps={{
                          "data-testid": `commands[${props.index}].username`,
                        }}
                        error={isUsernameInErrorState()}
                        helperText={getUsernameErrorMessage()}
                        id={`${formattedCommandType}Username`}
                        label={t("info.usernameLabel")}
                        variant="outlined"
                        className="form-field credentials"
                      />
                    )}
                  />
                </div>

                <div className="information-part-container credentials">
                  <Controller
                    name={`commands[${props.index}].password`}
                    control={control}
                    rules={registerOptions.password}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type={showPassword ? "text" : "password"}
                        inputProps={{
                          "data-testid": `commands[${props.index}].password`,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                }}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={isPasswordInErrorState()}
                        helperText={getPasswordErrorMessage()}
                        id={`${formattedCommandType}Password`}
                        label={t("info.passwordLabel")}
                        variant="outlined"
                        className="form-field credentials"
                      />
                    )}
                  />
                </div>
              </>
            )}
          </>
        )}
        {currentViewingMode === "viewing" && (
          <>
            <div className="information-part-container-header padding-top-only">
              {props.icon}
              <Typography variant="h5">{props.label}</Typography>
            </div>

            <Typography variant="subtitle1" data-testid="urlLabel">
              {t("info.urlLabel")}:{" "}
              {!deviceCommand.action || deviceCommand.action === ""
                ? t(`info.${formattedCommandType}NoActionDefined`)
                : deviceCommand.action}
            </Typography>

            {(formattedCommandType === "opendoor" ||
              formattedCommandType === "video" ||
              formattedCommandType === "image") && (
              <>
                <Typography variant="subtitle1" data-testid="usernameLabel">
                  {t("info.usernameLabel")}:{" "}
                  {!deviceCommand.username || deviceCommand.username === ""
                    ? t(`info.noUsernameDefined`)
                    : deviceCommand.username}
                </Typography>
                <Typography variant="subtitle1" data-testid="passwordLabel">
                  {t("info.passwordLabel")}:{" "}
                  {!deviceCommand.password || deviceCommand.password === ""
                    ? t(`info.noPasswordDefined`)
                    : getDottedPassword()}
                </Typography>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default DeviceCommandDetailsPopupSettings;
