import MonitoringFilter from "components/monitoring/monitoring-filter.component";
import { MonitoringType } from "components/monitoring/monitoring-type";
import { useExternalSystemsContextProvider } from "features/external-system/context/external-systems-provider";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";

function Offline(): ReactElement {
  const { t } = useTranslation("monitoring");
  const { externalSystemFilterHook } = useExternalSystemsContextProvider();

  return (
    <MonitoringFilter
      text={t("filter.externalSystemOffline")}
      type={MonitoringType.ExternalSystemOffline}
      countColor={Constants.Colors.error}
      count={
        externalSystemFilterHook.getMonitorFilter(
          MonitoringType.ExternalSystemOffline,
        ).count
      }
      isSelected={externalSystemFilterHook.isActiveMonitorFilter(
        MonitoringType.ExternalSystemOffline,
      )}
      onSelected={(selected) => {
        externalSystemFilterHook.selectMonitorFilter(
          MonitoringType.ExternalSystemOffline,
          selected,
        );
      }}
    />
  );
}

function PassiveOffline(): ReactElement {
  const { t } = useTranslation("monitoring");
  const { externalSystemFilterHook } = useExternalSystemsContextProvider();

  return (
    <MonitoringFilter
      text={t("filter.passiveExternalSystemOffline")}
      type={MonitoringType.PassiveExternalSystemOffline}
      countColor={Constants.Colors.brightOrange}
      count={
        externalSystemFilterHook.getMonitorFilter(
          MonitoringType.PassiveExternalSystemOffline,
        ).count
      }
      isSelected={externalSystemFilterHook.isActiveMonitorFilter(
        MonitoringType.PassiveExternalSystemOffline,
      )}
      onSelected={(selected) => {
        externalSystemFilterHook.selectMonitorFilter(
          MonitoringType.PassiveExternalSystemOffline,
          selected,
        );
      }}
    />
  );
}

function Online(): ReactElement {
  const { t } = useTranslation("monitoring");
  const { externalSystemFilterHook } = useExternalSystemsContextProvider();

  return (
    <MonitoringFilter
      text={t("filter.externalSystemOnline")}
      type={MonitoringType.ExternalSystemOnline}
      countColor={Constants.Colors.success}
      count={
        externalSystemFilterHook.getMonitorFilter(
          MonitoringType.ExternalSystemOnline,
        ).count
      }
      isSelected={externalSystemFilterHook.isActiveMonitorFilter(
        MonitoringType.ExternalSystemOnline,
      )}
      onSelected={(selected) => {
        externalSystemFilterHook.selectMonitorFilter(
          MonitoringType.ExternalSystemOnline,
          selected,
        );
      }}
    />
  );
}

function ServiceOffline(): ReactElement {
  const { t } = useTranslation("monitoring");
  const { externalSystemFilterHook } = useExternalSystemsContextProvider();

  return (
    <MonitoringFilter
      text={t("filter.externalSystemServiceOffline")}
      type={MonitoringType.ExternalSystemServiceOffline}
      countColor={Constants.Colors.brightOrange}
      count={
        externalSystemFilterHook.getMonitorFilter(
          MonitoringType.ExternalSystemServiceOffline,
        ).count
      }
      isSelected={externalSystemFilterHook.isActiveMonitorFilter(
        MonitoringType.ExternalSystemServiceOffline,
      )}
      onSelected={(selected) => {
        externalSystemFilterHook.selectMonitorFilter(
          MonitoringType.ExternalSystemServiceOffline,
          selected,
        );
      }}
    />
  );
}

const ExternalSystemMonitoringFilter = {
  MonitoringFilter: MonitoringFilter,
  Offline: Offline,
  PassiveOffline: PassiveOffline,
  Online: Online,
  ServiceOffline: ServiceOffline,
};

export default ExternalSystemMonitoringFilter;
