import { Divider, Switch, Typography } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./device-details-popup-settings.component.scss";
import DeviceSettingsOrganisation from "features/device/device-details/views/device-details-settings/device-settings-organisation";
import DeviceCommandDetailsPopupSettings from "../device-commands-detail-settings/device-command-details-popup-settings.component";
import { DeviceCommandType } from "features/device/models/device-command-type";
import DeviceSettingsType from "./device-settings-type";
import { useDeviceDetailsHook } from "../../hooks";
import { DeviceType } from "features/device/models/device-type";
import { OrganisationUnitTreeProvider } from "features/organisation/providers/organisation-unit-tree-provider";
import {
  VideocamOutlined,
  CallOutlined,
  DoorFrontOutlined,
  CloudOutlined,
  BroadcastOnPersonalOutlined,
  NfcRounded,
} from "@mui/icons-material";
import DeviceMonitoring from "./device-monitoring.component";
import DeviceSettingsFunction from "./device-settings-function";

function DeviceDetailsPopupSettings(): ReactElement {
  const { t } = useTranslation("deviceDetails");

  const {
    currentDeviceType,
    currentDevice,
    currentExternalSystemId,
    currentViewingMode,
    form,
    getDefaultExpectedRecurrence,
  } = useDeviceDetailsHook();

  const [showMonitoring, setShowMonitoring] = useState<boolean>(
    currentDevice?.deviceMonitoring?.expectedRecurrence !== undefined,
  );

  function getIndex(type: DeviceCommandType, defaultIndex: number): number {
    const index =
      currentDevice?.commands.findIndex((x) => x.type === type) ?? -1;

    const minimumIndex = currentDevice?.commands.length ?? 0;
    return index !== -1 ? index : minimumIndex + defaultIndex;
  }

  function getDeviceCommandDetails() {
    switch (currentDeviceType) {
      case DeviceType.UniversalAudio:
        return (
          <DeviceCommandDetailsPopupSettings
            index={getIndex(DeviceCommandType.Audio, 0)}
            commandType={DeviceCommandType.Audio}
            label={t("info.audioHeaderLabel")}
            icon={<CallOutlined />}
          />
        );
      case DeviceType.UniversalCamera:
        return (
          <>
            <DeviceCommandDetailsPopupSettings
              index={getIndex(DeviceCommandType.Video, 2)}
              commandType={DeviceCommandType.Video}
              label={t("info.videoHeaderLabel")}
              icon={<VideocamOutlined />}
            />
            <DeviceCommandDetailsPopupSettings
              index={getIndex(DeviceCommandType.Image, 1)}
              commandType={DeviceCommandType.Image}
              label={t("info.imageHeaderLabel")}
              icon={<VideocamOutlined />}
            />
            <DeviceCommandDetailsPopupSettings
              index={getIndex(DeviceCommandType.Audio, 0)}
              commandType={DeviceCommandType.Audio}
              label={t("info.audioHeaderLabel")}
              icon={<CallOutlined />}
            />
          </>
        );
      case DeviceType.UniversalIntercom:
        return (
          <>
            <DeviceCommandDetailsPopupSettings
              index={getIndex(DeviceCommandType.OpenDoor, 2)}
              commandType={DeviceCommandType.OpenDoor}
              label={t("info.doorHeaderLabel")}
              icon={<DoorFrontOutlined />}
            />
            <DeviceCommandDetailsPopupSettings
              index={getIndex(DeviceCommandType.Video, 1)}
              commandType={DeviceCommandType.Video}
              label={t("info.videoHeaderLabel")}
              icon={<VideocamOutlined />}
            />
            <DeviceCommandDetailsPopupSettings
              index={getIndex(DeviceCommandType.Audio, 0)}
              commandType={DeviceCommandType.Audio}
              label={t("info.audioHeaderLabel")}
              icon={<CallOutlined />}
            />
          </>
        );
      default:
        return <></>;
    }
  }

  function showDeviceSettings(): boolean {
    switch (currentDeviceType) {
      case DeviceType.UniversalAudio:
      case DeviceType.UniversalCamera:
      case DeviceType.UniversalIntercom:
        return true;
      default:
        return false;
    }
  }

  useEffect(() => {
    if (currentViewingMode === "viewing") {
      setShowMonitoring(
        currentDevice?.deviceMonitoring?.expectedRecurrence !== undefined,
      );
    } else {
      form.setValue(
        "deviceMonitoring.expectedRecurrence",
        showMonitoring ? getDefaultExpectedRecurrence() : undefined,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentViewingMode, currentDevice, currentDeviceType]);

  return (
    <div className="device-details-popup-settings-container">
      <div className="settings-item" data-testid="deviceOrganisationContainer">
        <Typography variant="h5">
          {t("settings.organisationUnitLabel")}
        </Typography>
        <div className="settings-item-content">
          <OrganisationUnitTreeProvider.ForExternalSystem
            externalSystemId={currentExternalSystemId}
          >
            <DeviceSettingsOrganisation />
          </OrganisationUnitTreeProvider.ForExternalSystem>
        </div>
      </div>
      <Divider />
      <div className="settings-item" data-testid="deviceTypeContainer">
        <div className="icon-text">
          <BroadcastOnPersonalOutlined width={24} height={24} />
          <Typography variant="h5">{t("info.typeTitle")}</Typography>
        </div>
        <DeviceSettingsType />
      </div>
      {currentDeviceType === DeviceType.NFC && (
        <>
          <Divider />
          <div className="settings-item" data-testid="deviceTypeContainer">
            <div className="icon-text">
              <NfcRounded width={24} height={24} />
              <Typography variant="h5">{t("info.functionTitle")}</Typography>
            </div>
            <DeviceSettingsFunction />
          </div>
        </>
      )}
      <Divider />
      {currentDeviceType !== DeviceType.NFC &&
        currentDeviceType !== DeviceType.ESPA &&
        (currentViewingMode !== "viewing" ||
          (currentViewingMode === "viewing" &&
            currentDevice?.deviceMonitoring?.expectedRecurrence !==
              undefined)) && (
          <>
            <div
              className="settings-item"
              data-testid="deviceMonitoringContainer"
            >
              <div className="header-row">
                <div className="icon-text">
                  <CloudOutlined width={24} height={24} />
                  <Typography variant="h5">{t("info.monitoring")}</Typography>
                </div>
                <Switch
                  type="checkbox"
                  disabled={currentViewingMode === "viewing"}
                  checked={showMonitoring}
                  onChange={(_, checked) => {
                    setShowMonitoring(checked);
                    form.setValue(
                      "deviceMonitoring.expectedRecurrence",
                      checked ? getDefaultExpectedRecurrence() : undefined,
                      { shouldDirty: true },
                    );
                  }}
                />
              </div>
              {showMonitoring && <DeviceMonitoring />}
            </div>
            <Divider />
          </>
        )}
      {showDeviceSettings() && (
        <div
          className="settings-item full-width"
          data-testid="deviceCommandsContainer"
        >
          <Typography variant="h5">{t("info.deviceSettingsLabel")}</Typography>
          <div className="settings-item-content full-width">
            {getDeviceCommandDetails()}
          </div>
        </div>
      )}
    </div>
  );
}

export default DeviceDetailsPopupSettings;
