import { Close } from "@mui/icons-material";
import CheckRounded from "@mui/icons-material/CheckRounded";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { LoadingButton } from "@mui/lab";
import { Button, IconButton } from "@mui/material";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import { useExternalSystemsContextProvider } from "features/external-system/context/external-systems-provider";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./external-system-details-popup-header.module.scss";

const ExternalSystemDetailsPopupHeader = (): JSX.Element => {
  const { t } = useTranslation("externalSystem");

  const { formState } = useFormContext();
  const { externalSystemDetailsHook } = useExternalSystemsContextProvider();
  const {
    cancelMutation,
    mutationIsLoading,
    deleteExternalSystem,
    currentSelectedExternalSystem,
    openDetailsInEditingMode,
    closeDetails,
  } = externalSystemDetailsHook;

  const isViewing = externalSystemDetailsHook.viewingMode === "viewing";
  const isCreating = externalSystemDetailsHook.viewingMode === "creation";
  const isEditing = externalSystemDetailsHook.viewingMode === "editing";

  return (
    <div className={styles.container}>
      {(isCreating || isEditing) && (
        <>
          <Button
            data-testid="externalSystemDetailCancelButton"
            variant="text"
            onClick={
              isCreating
                ? () => closeDetails(formState.isDirty)
                : cancelMutation
            }
            disabled={mutationIsLoading}
          >
            {t("details.cancelFormButton")}
          </Button>
          <LoadingButton
            data-testid="externalSystemDetailSubmitButton"
            startIcon={<CheckRounded />}
            variant="contained"
            type="submit"
            loading={mutationIsLoading}
            loadingPosition="start"
          >
            {t("details.saveFormButton")}
          </LoadingButton>
        </>
      )}
      {isViewing && (
        <>
          <AutorisationWrapper
            atLeastOnePermissionOf={[Permission.DeleteExternalSystem]}
          >
            <Button
              data-testid="externalSystemDetailRemoveButton"
              startIcon={<DeleteForeverOutlinedIcon />}
              variant="text"
              onClick={() =>
                deleteExternalSystem(currentSelectedExternalSystem!)
              }
              disabled={mutationIsLoading}
            >
              {t("details.removeFormButton")}
            </Button>
          </AutorisationWrapper>
          <AutorisationWrapper
            atLeastOnePermissionOf={[Permission.UpdateExternalSystem]}
          >
            <Button
              data-testid="externalSystemDetailEditButton"
              startIcon={<ModeEditOutlineOutlinedIcon />}
              variant="text"
              onClick={() =>
                openDetailsInEditingMode(currentSelectedExternalSystem!)
              }
              disabled={mutationIsLoading}
            >
              {t("details.editFormButton")}
            </Button>
          </AutorisationWrapper>
        </>
      )}

      <IconButton
        aria-label="close"
        className={styles.closeButton}
        onClick={() => closeDetails(formState.isDirty)}
        data-testid="externalSystemDetailsPopupCloseButton"
      >
        <Close />
      </IconButton>
    </div>
  );
};

export default ExternalSystemDetailsPopupHeader;
