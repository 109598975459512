import { Link as LinkIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { TextField, Typography } from "@mui/material";
import CopyButton from "components/copy-button/copy-button.component";
import PopUp from "components/pop-up/pop-up.component";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import { useExternalSystemsContextProvider } from "features/external-system/context/external-systems-provider";
import ExternalSystem from "features/external-system/domain/models/external-system";
import useExternalSystemSettingsIoTEdgeClusterClient from "features/external-system/hooks/external-system-settings-iot-edge-cluster-client-hook";
import { Controller, useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import styles from "./external-system-settings-client.module.scss";

const ExternalSystemSettingsIoTEdgeClusterDevice = (
  childExternalSystem: ExternalSystem | undefined,
  index: number,
): JSX.Element => {
  const { t } = useTranslation("externalSystem");

  const { externalSystemDetailsHook } = useExternalSystemsContextProvider();

  const {
    ioTEdgeConnectionString,
    generateIoTEdgeConnectionStringIsLoading,
    isGenerateConfirmationOpen,
    defaultIoTEdgeConnectionString,
    generateIoTEdgeClusterDeviceConnectionString:
      generateIoTEdgeConnectionString,
    submitGenerateIoTEdgeClusterDeviceConnectionString:
      submitGenerateIoTEdgeConnectionString,
    closeGenerateConfirmationPopup,
  } = useExternalSystemSettingsIoTEdgeClusterClient(childExternalSystem);

  const { control } = useFormContext();

  return (
    <div className={styles.clusterItem}>
      {externalSystemDetailsHook.viewingMode === "editing" ||
      externalSystemDetailsHook.viewingMode === "creation" ? (
        <Controller
          name={`childExternalSystems[${index}].name`}
          control={control}
          rules={{ required: t("details.information.requiredHelperText") }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              inputProps={{ "data-testid": "nameInput", maxLength: 255 }}
              id={`nameInput-${index}`}
              label={t("details.information.nameLabel")}
              variant="outlined"
              error={!!error}
              helperText={error?.message?.toString()}
              className={styles.textfield}
            />
          )}
        />
      ) : (
        <Typography variant="subtitle1">
          {childExternalSystem?.name ?? ""}
        </Typography>
      )}
      <div className={styles.container}>
        <div className={styles.infoItem}>
          <Typography variant="subtitle1">
            {t("details.settings.ioTEdgeDeviceIdLabel")}:
          </Typography>
          <Typography variant="subtitle1">
            {t("details.settings.ioTEdgeConnectionStringLabel")}:
          </Typography>
        </div>
        <div className={styles.infoItem}>
          <div className={styles.secret}>
            <Typography variant="subtitle1">
              {childExternalSystem?.deviceId ?? "-"}
            </Typography>
            {childExternalSystem?.deviceId !== undefined && (
              <CopyButton contentToCopy={childExternalSystem.deviceId} />
            )}
          </div>
          <div className={styles.secret}>
            <Typography variant="subtitle1">
              {ioTEdgeConnectionString}
            </Typography>
            {ioTEdgeConnectionString !== defaultIoTEdgeConnectionString && (
              <CopyButton contentToCopy={ioTEdgeConnectionString} />
            )}
          </div>
        </div>
      </div>
      <AutorisationWrapper
        atLeastOnePermissionOf={[Permission.CreateExternalSystem]}
      >
        <>
          {externalSystemDetailsHook.viewingMode === "viewing" &&
            (ioTEdgeConnectionString === defaultIoTEdgeConnectionString ? (
              <LoadingButton
                variant="outlined"
                onClick={generateIoTEdgeConnectionString}
                startIcon={<LinkIcon />}
                loading={generateIoTEdgeConnectionStringIsLoading}
                loadingPosition="start"
                data-testid="generate-secret-button"
              >
                {t("details.settings.clientSecret.generateButton")}
              </LoadingButton>
            ) : (
              <Typography variant="subtitle1">
                {t("details.settings.clientSecret.warningIoTEdge")}
              </Typography>
            ))}
          <PopUp
            isOpen={isGenerateConfirmationOpen}
            title={t("details.settings.clientSecret.confirmation.title")}
            body={
              <Trans
                t={t}
                i18nKey="details.settings.clientSecret.confirmation.bodyText"
              />
            }
            primaryButtonText={t(
              "details.settings.clientSecret.confirmation.confirmButton",
            )}
            secondaryButtonText={t(
              "details.settings.clientSecret.confirmation.cancelButton",
            )}
            handleOnClose={closeGenerateConfirmationPopup}
            secondaryButtonAction={closeGenerateConfirmationPopup}
            primaryButtonAction={submitGenerateIoTEdgeConnectionString}
          />
        </>
      </AutorisationWrapper>
    </div>
  );
};

const ExternalSystemSettingsIoTEdgeClusterClient = (): JSX.Element => {
  const { externalSystemDetailsHook } = useExternalSystemsContextProvider();

  return (
    <>
      {ExternalSystemSettingsIoTEdgeClusterDevice(
        externalSystemDetailsHook.currentSelectedExternalSystem?.childExternalSystems?.find(
          (x) => x.isActiveInCluster,
        ),
        0,
      )}
      {ExternalSystemSettingsIoTEdgeClusterDevice(
        externalSystemDetailsHook.currentSelectedExternalSystem?.childExternalSystems?.find(
          (x) => !x.isActiveInCluster,
        ),
        1,
      )}
    </>
  );
};

export default ExternalSystemSettingsIoTEdgeClusterClient;
