import { useTranslation } from "react-i18next";
import "./residents-settings.scss";
import PageHeader from "components/page-header/page-header";
import { ReactElement } from "react";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import Table, { IRowItem } from "components/table/table";
import Resident from "../domain/models/resident";
import { ColumnDefinition } from "components/table/columnDefinition";
import BroadcastOnPersonalOutlinedIcon from "@mui/icons-material/BroadcastOnPersonalOutlined";
import { NestedKeyof } from "utils/nested-keyof-utils";
import theme from "style/theme";
import ResidentDetails from "./resident-details/resident-details";
import ResidentRow from "./resident-row";
import { useResidentSettingsContextProvider } from "features/residents/residents-settings/context/resident-settings-provider";
import ResidentAlarmScenarios from "./resident-alarm-scenarios/resident-alarm-scenarios";
import { Badge, Typography } from "@mui/material";
import ResidentFilters from "./resident-filters/resident-filters";
import { FaultType } from "models/fault-type";
import Constants from "style/constants";
import { WarningAmberRounded } from "@mui/icons-material";
import ResidentsBulkEdit from "./residents-bulk-edit/residents-bulk-edit";

function ResidentsSettings(): ReactElement {
  const { t } = useTranslation("residentsSettings");

  const {
    readResidentsResponse,
    readResidentsIsLoading,
    readResidentsIsSuccess,

    residentSettingsFiltersHook,
    residentDetailsHook,
    residentsBulkEditHook,
    openDetailsAndRefresh,
    setSelectedResidents,
  } = useResidentSettingsContextProvider();

  const residentDeviceStatusIconColor = (resident: Resident) => {
    if (
      resident.faults.some(
        (fault) =>
          fault === FaultType.BatteryCritical ||
          fault === FaultType.ExternalSystemDown ||
          fault === FaultType.Offline ||
          fault === FaultType.OutDated,
      )
    ) {
      return "error";
    }

    if (resident.faults.some((fault) => fault === FaultType.BatteryLow)) {
      return "warning";
    }

    return "primary";
  };

  const columns: ColumnDefinition<Resident, NestedKeyof<Resident>>[] = [
    {
      key: "organisationUnitName",
      label: t("residentsTable.column.organisationUnitName"),
      renderCustomHeader: () => {
        return (
          <div className="resident-header-container">
            {t("residentsTable.column.organisationUnitName")}
          </div>
        );
      },
      renderCustomContentProvider: (resident) => (
        <ResidentRow
          residentName={resident.organisationUnitName}
          breadCrumbs={resident.parentsBreadCrumb}
          isSelected={
            resident.organisationUnitId ===
            residentDetailsHook.currentResident?.organisationUnitId
          }
        ></ResidentRow>
      ),
      disableSort: true,
    },
    {
      key: "organisationUnitId",
      label: t("residentsTable.column.alarmScenarios"),
      renderCustomContentProvider: (resident) => (
        <ResidentAlarmScenarios
          organisationUnitScenarios={resident.organisationUnitScenarios}
        />
      ),
      disableSort: true,
    },
    {
      key: "devices.length",
      label: t("residentsTable.column.device"),
      renderCustomContentProvider: (resident) => (
        <div className="devices">
          <BroadcastOnPersonalOutlinedIcon
            className="device-icon"
            sx={{
              color:
                resident.devices.length === 0
                  ? Constants.Colors.inactive
                  : Constants.Colors.primary,
            }}
          />
          {resident.devices.length > 1 && (
            <Typography
              variant="body2"
              className="devices-amount"
              sx={{
                color: Constants.Colors.primary,
              }}
            >
              ({resident.devices.length})
            </Typography>
          )}
        </div>
      ),
      disableSort: true,
    },
    {
      key: "faults.length",
      label: t("residentsTable.column.deviceStatus"),
      renderCustomContentProvider: (resident) => (
        <>
          {resident.faults?.length > 0 && (
            <div className="devices">
              <Badge
                badgeContent={resident.faults.length}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                color={residentDeviceStatusIconColor(resident)}
              >
                <WarningAmberRounded
                  className="device-icon"
                  sx={{
                    color: Constants.Colors.onSurface,
                    width: 24,
                    height: 24,
                  }}
                />
              </Badge>
            </div>
          )}
        </>
      ),
      disableSort: true,
    },
  ];

  return (
    <div
      className="residents-settings-container"
      data-testid="residentsSettingsContainer"
    >
      <div
        className={`residents-settings-structure-container ${
          (residentsBulkEditHook.viewingMode !== "none" ||
            residentDetailsHook.viewingMode !== "none") &&
          "detail-is-opened"
        }`}
        style={{
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shortest,
          }),
        }}
      >
        <PageHeader
          title={t("pageTitle")}
          isAddActionVisible={false}
          filterComponent={
            <>
              <ResidentFilters />{" "}
              {residentsBulkEditHook.selectedResidents.length > 0 && (
                <div className="selected-residents-container">
                  <Typography className="selected-residents-label">
                    {`${residentsBulkEditHook.selectedResidents.length} ${t(
                      "bulkEditScenarios.selected",
                    )}`}
                  </Typography>
                </div>
              )}
            </>
          }
        />
        {readResidentsIsLoading && <LoadingIndicator />}

        {readResidentsIsSuccess && readResidentsResponse && (
          <Table
            className="residents-container"
            data={readResidentsResponse.residents.map<IRowItem<Resident>>(
              (resident) => ({ data: resident }),
            )}
            columns={columns}
            currentItem={readResidentsResponse?.residents.find(
              (resident) =>
                resident.organisationUnitId ===
                residentDetailsHook.currentResident?.organisationUnitId,
            )}
            onItemClick={(resident: Resident) =>
              openDetailsAndRefresh(resident)
            }
            enablePagination={true}
            count={readResidentsResponse.total}
            onPageChanged={
              residentSettingsFiltersHook.table.handleOnPageChanged
            }
            rowsPerPage={residentSettingsFiltersHook.table.currentRowsPerPage}
            page={residentSettingsFiltersHook.table.currentPage}
            onRowsPerPageChanged={
              residentSettingsFiltersHook.table.handleOnRowsPerPageChanged
            }
            rowIdentifier={(item: Resident) => item.organisationUnitId}
            rowsAreSelectable={true}
            onSelectedRowsChanged={(residents) => {
              setSelectedResidents(residents);
            }}
            selectedRows={residentsBulkEditHook.selectedResidents?.map<
              IRowItem<Resident>
            >((resident) => ({ data: resident }))}
            onSelectAllRowsChanged={(checked) => {
              setSelectedResidents(
                checked ? readResidentsResponse?.residents ?? [] : [],
              );
            }}
            allRowsAreSelected={residentsBulkEditHook.allRowsAreSelected}
          />
        )}
      </div>
      <div
        className={`resident-detail-container ${
          residentDetailsHook.viewingMode !== "none" && "detail-is-opened"
        }`}
      >
        <ResidentDetails />
      </div>

      <div
        className={`resident-detail-container ${
          residentDetailsHook.viewingMode === "none" &&
          residentsBulkEditHook.viewingMode !== "none" &&
          "detail-is-opened"
        }`}
      >
        <ResidentsBulkEdit />
      </div>
    </div>
  );
}

export default ResidentsSettings;
