import PageHeader from "components/page-header/page-header";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLazyReadHistoryQuery } from "../domain/reducers/history.reducer";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import Table, { IRowItem } from "components/table/table";
import { ColumnDefinition } from "components/table/columnDefinition";
import { NestedKeyof } from "utils/nested-keyof-utils";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import { useAppDispatch } from "redux-base/store";
import moment, { Moment } from "moment";
import BreadCrumb from "components/bread-crumb/bread-crumb.component";
import { IconButton, Typography } from "@mui/material";
import Category from "./category.component";
import "./history.component.scss";
import { HistoryItem } from "../domain/models/history-item";
import { HistoryItemType } from "../domain/models/history-item-type";
import { AlarmPriority } from "../domain/models/alarm-priority";
import HistoryFilters, { HistoryFilterValues } from "./history-filters";
import { AlarmType } from "../domain/models/alarm-type";
import { RefreshRounded } from "@mui/icons-material";
import useTableHook from "hooks/table-hook";
import { useSessionStorage } from "usehooks-ts";
import ObjectSort from "models/object-sort";
import { AlarmStatus } from "../domain/models/alarm-status";
import HistoryDetails from "./details/history-details.component";
import { useHistoryContextProvider } from "../providers/history-provider";
import { useAuth } from "features/authentication/providers/authentication.provider";

function History(): ReactElement {
  const { t } = useTranslation("history");
  const sessionStorageFiltersKey = "history-filters";
  const sessionStorageSortingKey = "history-sorting";
  const dispatch = useAppDispatch();

  const { currentSelectedItem, setCurrentSelectedItem } =
    useHistoryContextProvider();
  const { hasPermission } = useAuth();

  const [originalHistory, setOriginalHistory] = useState<HistoryItem[]>([]);

  const {
    currentPage,
    currentRowsPerPage,
    handleOnPageChanged,
    handleOnRowsPerPageChanged,
  } = useTableHook();

  const [filtersFromSessionStorage] = useSessionStorage<HistoryFilterValues>(
    sessionStorageFiltersKey,
    {},
  );
  const [filters, setFilters] = useState<HistoryFilterValues>(
    filtersFromSessionStorage,
  );

  const [sortFromSessionStorage, setSortFromSessionStorage] = useSessionStorage<
    ObjectSort<HistoryItem>
  >(sessionStorageSortingKey, {
    property: "createdOn",
    isAscending: false,
  });

  const [
    triggerReadHistory,
    {
      data: readHistoryData,
      isSuccess: readHistoryIsSuccess,
      error: readHistoryError,
      isFetching: readHistoryIsFetching,
    },
  ] = useLazyReadHistoryQuery();

  const isGenericOrEmergency = (item: HistoryItem): boolean =>
    item.type === AlarmType.GenericCall || item.type === AlarmType.Emergency;

  const columns: ColumnDefinition<HistoryItem, NestedKeyof<HistoryItem>>[] = [
    {
      key: "id",
      label: t("historyTable.column.category"),
      renderCustomContentProvider: (item) => (
        <Category
          alarmPriority={item.priority ?? AlarmPriority.Unknown}
          historyType={item.historyType}
        />
      ),
      disableSort: true,
    },
    {
      key: "type",
      label: t("historyTable.column.type"),
      renderCustomContentProvider: (item) => (
        <div className="alarm-type">
          <Typography variant="subtitle1">
            {isGenericOrEmergency(item)
              ? item.message
              : t(`type.${item.historyType}.${item.type}`)}
          </Typography>
          {item.message && !isGenericOrEmergency(item) && (
            <Typography variant="caption">{item.message}</Typography>
          )}
          {item.nearbyText && (
            <Typography variant="caption">{`${t("type.Alarm.nearby")} ${
              item.nearbyText
            }`}</Typography>
          )}
        </div>
      ),
      disableSort: true,
    },
    {
      key: "organisationUnitsWithAncestors.length",
      label: t("historyTable.column.breadCrumbs"),
      renderCustomContentProvider: (item) => (
        <div className="breadcrumb-container">
          {item?.organisationUnitsWithAncestors.map((organisationUnits, i) => (
            <BreadCrumb
              key={`breadcrumb-${item.id}-${i}`}
              showEllipsis={organisationUnits.length > 2}
              allElementNames={organisationUnits.map((unit) => unit.name)}
              data-testid={`history-breadcrumb-child-${item.id}`}
            >
              {organisationUnits?.map((unit, index) => {
                return (
                  <Typography
                    data-testid={`history-breadcrumb-child-${index}`}
                    key={`History_Breadcrumb_Child_${unit.name}`}
                    variant="caption"
                    className="bread-crumb-item"
                  >
                    {unit.name}
                  </Typography>
                );
              })}
            </BreadCrumb>
          ))}
        </div>
      ),
      disableSort: true,
    },
    {
      key: "createdOn",
      label: t("historyTable.column.createdOn"),
      renderCustomContentProvider: (item) => (
        <>{moment(item.createdOn).format("DD-MM-YYYY · HH:mm:ss").toString()}</>
      ),
      disableSort: false,
    },
    {
      key: "statusLastChangedAt",
      label: t("historyTable.column.statusLastChangedAt"),
      renderCustomContentProvider: (item) => {
        if (item.status === AlarmStatus.Handled && item.statusLastChangedAt) {
          return (
            <>
              {moment(item.statusLastChangedAt)
                .format("DD-MM-YYYY · HH:mm:ss")
                .toString()}
            </>
          );
        } else if (
          item.status !== AlarmStatus.Handled &&
          item.historyType === HistoryItemType.Alarm
        ) {
          return (
            <div
              className={`status ${
                item.status === AlarmStatus.Reported
                  ? "background-color-reported"
                  : "background-color-accepted"
              }`}
            >
              <Typography className="statusText" variant="overline">
                {t(`status.${item.status}`)}
              </Typography>
            </div>
          );
        }

        return <></>;
      },
      disableSort: false,
    },
    {
      key: "duration",
      label: t("historyTable.column.duration"),
      renderCustomContentProvider: (item) => (
        <>{getDurationWithDaysInHours(item)}</>
      ),
      disableSort: false,
    },
    {
      key: "isDiverted",
      label: "",
      renderCustomContentProvider: (alarm) =>
        alarm.isDiverted ? (
          <Typography className="is-diverted" variant="overline">
            {t("isDiverted")}
          </Typography>
        ) : (
          <></>
        ),
      disableSort: true,
    },
  ];

  function getDurationWithDaysInHours(item: HistoryItem): string {
    if (
      item.historyType === HistoryItemType.Status ||
      (item.type === AlarmType.EmergencyAlarm &&
        item.status === AlarmStatus.Cancelled)
    ) {
      return "";
    }

    let duration = moment.duration(item.duration);

    return formatFromMilliseconds(duration.asMilliseconds());
  }

  function formatFromMilliseconds(milliseconds: number): string {
    if (milliseconds < 1000) {
      return "00:00:00";
    }

    const totalSeconds = Math.floor(milliseconds / 1000);
    const formattedHours = Math.floor(totalSeconds / 3600);
    const remainingSeconds = totalSeconds % 3600;
    const formattedMinutes = Math.floor(remainingSeconds / 60);
    const formattedSeconds = remainingSeconds % 60;

    return `${formattedHours}:${String(formattedMinutes).padStart(
      2,
      "0",
    )}:${String(formattedSeconds).padStart(2, "0")}`;
  }

  function getDate(date: Moment | null | undefined): Date | undefined {
    if (date) {
      return new Date(date.toString());
    }
    return undefined;
  }

  function triggerReadHistoryPeriod() {
    let from = getDate(filters?.dateFilters?.dateFrom);
    let to = getDate(filters?.dateFilters?.dateTo);

    const command = {
      from: from,
      to: to,
      category: filters?.categoryFilter,
      types: filters?.typeFilters ?? [],
      organisationUnitIds:
        filters?.organisationUnitFilters?.map((x) => x.id) ?? [],
      isDiverted:
        filters?.diversionFilter !== undefined
          ? filters?.diversionFilter === "true"
          : undefined,
      status: filters?.statusFilter,

      currentPage: currentPage,
      currentRowsPerPage: currentRowsPerPage,

      sort: sortFromSessionStorage.property,
      isAscending: sortFromSessionStorage.isAscending,
    };

    triggerReadHistory(command);
  }

  useEffect(() => {
    if (
      readHistoryData &&
      readHistoryData.historyRows.length > 0 &&
      originalHistory.length === 0
    ) {
      setOriginalHistory(readHistoryData.historyRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readHistoryData]);

  useEffect(
    triggerReadHistoryPeriod,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, sortFromSessionStorage, currentPage, currentRowsPerPage],
  );

  useEffect(
    () => {
      handleOnPageChanged(0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters],
  );

  useEffect(() => {
    if (readHistoryError) {
      dispatch(
        setErrorMessage({
          error: readHistoryError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readHistoryError]);

  return (
    <AutorisationWrapper atLeastOnePermissionOf={[Permission.ReadHistory]}>
      <div className="history-container">
        <PageHeader
          title={t("pageHeaderTitle")}
          isAddActionVisible={false}
          filterComponent={
            readHistoryIsSuccess ? (
              <div className="history-filter-component-container">
                <HistoryFilters
                  historyItems={originalHistory}
                  onFilterChanged={setFilters}
                  allTypes={readHistoryData?.allTypes}
                  allMessageTypes={readHistoryData?.allMessageTypes}
                />
                <IconButton
                  className="refresh-button"
                  onClick={triggerReadHistoryPeriod}
                >
                  <RefreshRounded />
                </IconButton>
              </div>
            ) : (
              <></>
            )
          }
        />
        {readHistoryIsFetching && <LoadingIndicator />}
        {readHistoryIsSuccess && readHistoryData && (
          <Table
            data={readHistoryData.historyRows.map<IRowItem<HistoryItem>>(
              (e: HistoryItem) => ({
                isHighlighted:
                  e.status !== AlarmStatus.Handled &&
                  e.status !== AlarmStatus.Cancelled &&
                  e.historyType === HistoryItemType.Alarm,
                isClickable: e.historyType === HistoryItemType.Alarm,
                data: e,
              }),
            )}
            columns={columns}
            enablePagination={true}
            rowIdentifier={(item: HistoryItem) => item.id}
            onSortChanged={(
              property: NestedKeyof<HistoryItem>,
              isAscending: boolean,
            ) => setSortFromSessionStorage({ property, isAscending })}
            count={readHistoryData.allItemsCount}
            onPageChanged={handleOnPageChanged}
            rowsPerPage={currentRowsPerPage}
            page={currentPage}
            onRowsPerPageChanged={handleOnRowsPerPageChanged}
            initialOrderBy={sortFromSessionStorage.property}
            initialOrderDirection={
              sortFromSessionStorage.isAscending ? "asc" : "desc"
            }
            onItemClick={
              hasPermission(Permission.ReadHistoryDetails)
                ? (item: HistoryItem) => setCurrentSelectedItem(item)
                : undefined
            }
          />
        )}
        {currentSelectedItem && <HistoryDetails />}
      </div>
    </AutorisationWrapper>
  );
}

export default History;
