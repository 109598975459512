import { MenuItem, Typography } from "@mui/material";
import SelectField from "components/formFields/select-field.component";
import { useExternalSystemsContextProvider } from "features/external-system/context/external-systems-provider";
import { ExternalSystemType } from "features/external-system/domain/models/external-system-type";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const ExternalSystemSettingsType = (): JSX.Element => {
  const { t } = useTranslation("externalSystem");

  const {
    formState: { errors },
    control,
  } = useFormContext();
  const registerOptions = {
    type: {
      required: t("details.information.requiredHelperText"),
    },
  };

  const { externalSystemDetailsHook } = useExternalSystemsContextProvider();
  const isCreating = externalSystemDetailsHook.viewingMode === "creation";
  const menuItems = Object.values(ExternalSystemType).filter(
    (systemType) => systemType !== ExternalSystemType.None,
  );

  if (isCreating) {
    return (
      <Controller
        name="type"
        control={control}
        rules={registerOptions.type}
        render={({ field }) => {
          return (
            <SelectField
              {...field}
              inputProps={{ "data-testid": "typeInput" }}
              id="typeInput"
              error={!!errors.type}
              helperText={errors.type?.message?.toString()}
              variant="outlined"
            >
              {menuItems.map((systemType) => (
                <MenuItem
                  data-testid={`external-system-type-${systemType}`}
                  key={`externalSystemType-${systemType}`}
                  value={systemType}
                >
                  {t(`details.settings.type.${systemType}`)}
                </MenuItem>
              ))}
            </SelectField>
          );
        }}
      />
    );
  }

  return (
    <Typography variant="subtitle1" data-testid="externalSystemSettingsType">
      {t(
        `details.settings.type.${externalSystemDetailsHook.currentSelectedExternalSystem?.type}`,
      )}
    </Typography>
  );
};

export default ExternalSystemSettingsType;
