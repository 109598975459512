import { Link as LinkIcon, LinkOff as LinkOffIcon } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import PopUp from "components/pop-up/pop-up.component";
import { useExternalSystemsContextProvider } from "features/external-system/context/external-systems-provider";
import useExternalSystemOrganisation from "features/external-system/hooks/external-system-settings-organisation-hook";
import OrganisationBreadCrumb from "features/organisation/views/organisation-bread-crumb/organisation-bread-crumb.component";
import OrganisationPicker from "features/organisation/views/organisation-picker/organisation-picker";
import { useTranslation } from "react-i18next";
import styles from "./external-system-settings-organisation.module.scss";

const ExternalSystemSettingsOrganisation = (): JSX.Element => {
  const { t } = useTranslation("externalSystem");

  const { externalSystemDetailsHook } = useExternalSystemsContextProvider();

  const {
    isLinkErrorPopupOpen,
    isOrganisationPickerOpen,
    upstreamParentBranch,
    organisationUnitField,
    setOrganisationUnit,
    linkOrganisationUnit,
    unlinkOrganisationUnit,
    closeLinkErrorPopup,
    closeOrganisationPicker,
  } = useExternalSystemOrganisation();

  return (
    <>
      {organisationUnitField.value ? (
        <div className={styles.container}>
          <OrganisationBreadCrumb
            rootNodeId={organisationUnitField.value}
            upstreamParentBranch={upstreamParentBranch}
          />
        </div>
      ) : (
        <Typography variant="subtitle1">
          {t("details.settings.noOrganisationUnitLinkedLabel")}
        </Typography>
      )}
      {externalSystemDetailsHook.viewingMode !== "viewing" &&
        (organisationUnitField.value ? (
          <Button
            variant="outlined"
            onClick={unlinkOrganisationUnit}
            startIcon={<LinkOffIcon />}
            data-testid="unlinkOrganisationButton"
          >
            {t("details.settings.unlinkOrganisationUnitButton")}
          </Button>
        ) : (
          <Button
            variant="outlined"
            onClick={linkOrganisationUnit}
            startIcon={<LinkIcon />}
            data-testid="linkOrganisationButton"
          >
            {t("details.settings.linkOrganisationUnitButton")}
          </Button>
        ))}
      <OrganisationPicker
        isOpen={isOrganisationPickerOpen}
        onClose={closeOrganisationPicker}
        onOrganisationUnitSelected={(selectedNode, upstreamParentBranch) =>
          setOrganisationUnit(
            organisationUnitField,
            selectedNode,
            upstreamParentBranch,
          )
        }
      />

      <PopUp
        isOpen={isLinkErrorPopupOpen}
        title={t("linkSystemError.title", {
          externalSystemName:
            externalSystemDetailsHook.currentSelectedExternalSystem?.name,
        })}
        body={t("linkSystemError.bodyText", {
          externalSystemName:
            externalSystemDetailsHook.currentSelectedExternalSystem?.name,
        })}
        primaryButtonText={t("linkSystemError.confirmButton")}
        handleOnClose={closeLinkErrorPopup}
        primaryButtonAction={closeLinkErrorPopup}
      />
    </>
  );
};

export default ExternalSystemSettingsOrganisation;
