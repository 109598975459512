import { CommentOutlined } from "@mui/icons-material";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import PageHeader from "components/page-header/page-header";
import PopUp from "components/pop-up/pop-up.component";
import { ColumnDefinition } from "components/table/columnDefinition";
import Table, { IRowItem, TableOverflowMenuItem } from "components/table/table";
import { useAuth } from "features/authentication/providers/authentication.provider";
import Permission from "features/autorisation/domain/models/permission";
import HardwareNavigation from "features/hardware/views/hardware-navigation";
import { findUpstreamParentBranch } from "features/organisation/domain/models/organisation-tree-node";
import OrganisationBreadCrumb from "features/organisation/views/organisation-bread-crumb/organisation-bread-crumb.component";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import ExternalSystemDetailsPopup from "./external-system-details/external-system-details-popup.component";
import "./external-systems.scss";
import { useOrganisationUnitTreeContext } from "features/organisation/providers/organisation-unit-tree-provider";
import { useExternalSystemsContextProvider } from "../context/external-systems-provider";
import ExternalSystemFilters from "./external-system-filters";
import { MonitoringType } from "components/monitoring/monitoring-type";
import MonitoringIcon from "components/monitoring/monitoring-icon.component";
import Constants from "style/constants";
import ExternalSystem from "../domain/models/external-system";
import { ExternalSystemStatus } from "../domain/models/external-system-status";

function ExternalSystems(): ReactElement {
  const { t } = useTranslation("externalSystem");
  const { hasPermission } = useAuth();
  const { organisationUnitTree } = useOrganisationUnitTreeContext();

  const {
    externalSystemsResponse,
    externalSystemsResponseIsLoading,
    externalSystemsResponseIsSuccess,
    externalSystemDetailsHook,
    externalSystemFilterHook,
    openDetailsInCreationModeAndRefresh,
    openDetailsInEditingModeAndRefresh,
    openDetailsInViewingModeAndRefresh,
  } = useExternalSystemsContextProvider();

  function getOrganisationBreadCrumb(
    externalSystem: ExternalSystem,
  ): ReactElement {
    if (externalSystem.organisationUnitId && organisationUnitTree) {
      const upstreamParentBranch = findUpstreamParentBranch(
        organisationUnitTree,
        externalSystem.organisationUnitId,
        [],
      );
      if (upstreamParentBranch) {
        return (
          <OrganisationBreadCrumb
            rootNodeId={externalSystem.organisationUnitId}
            upstreamParentBranch={upstreamParentBranch}
            data-testId="organisationBreadCrumb"
          />
        );
      }
    }
    return <></>;
  }

  const columns: ColumnDefinition<ExternalSystem, keyof ExternalSystem>[] = [
    { key: "name", label: t("nameHeaderLabel") },
    {
      key: "type",
      label: t("typeHeaderLabel"),
      renderCustomContentProvider: (externalSystem) => (
        <>{t(`details.settings.type.${externalSystem.type}`)}</>
      ),
    },
    {
      key: "organisationUnitId",
      label: t("organisationUnitLabel"),
      renderCustomContentProvider: getOrganisationBreadCrumb,
      disableSort: true,
      tableCellProps: {
        classes: { root: "external-system-list-organisation-bread-crumb" },
      },
    },
    {
      key: "note",
      label: "",
      renderCustomContentProvider: (externalSystem) => (
        <div className="icon-row">{getIcons(externalSystem)}</div>
      ),
      disableSort: true,
      tableCellProps: {
        align: "right",
        width: "24px",
      },
    },
  ];

  function getIcons(externalSystem: ExternalSystem): JSX.Element[] {
    const result: JSX.Element[] = [];

    result.push(
      <CommentOutlined
        data-testid="noteIcon"
        key={"note-icon"}
        sx={{
          width: 16,
          height: 16,
          color: Constants.Colors.onSurfaceVariant,
          visibility: externalSystem.note ? "visible" : "hidden",
        }}
      />,
    );

    if (externalSystem.status === ExternalSystemStatus.offline) {
      result.push(
        <MonitoringIcon
          key={`monitoring-icon-${MonitoringType.ExternalSystemOffline}`}
          type={MonitoringType.ExternalSystemOffline}
          withTooltip={true}
          visible={true}
          size={16}
        />,
      );
    } else if (
      externalSystem.status === ExternalSystemStatus.serviceOfflineOnly
    ) {
      result.push(
        <MonitoringIcon
          key={`monitoring-icon-${MonitoringType.ExternalSystemServiceOffline}`}
          type={MonitoringType.ExternalSystemServiceOffline}
          withTooltip={true}
          visible={true}
          size={16}
        />,
      );
    } else if (
      externalSystem.status === ExternalSystemStatus.passiveOfflineOnly
    ) {
      result.push(
        <MonitoringIcon
          key={`monitoring-icon-${MonitoringType.PassiveExternalSystemOffline}`}
          type={MonitoringType.PassiveExternalSystemOffline}
          withTooltip={true}
          visible={true}
          size={16}
        />,
      );
    } else if (externalSystem.status === ExternalSystemStatus.online) {
      result.push(
        <MonitoringIcon
          key={`monitoring-icon-${MonitoringType.ExternalSystemOnline}`}
          type={MonitoringType.ExternalSystemOnline}
          withTooltip={true}
          visible={true}
          size={16}
        />,
      );
    } else {
      result.push(
        <MonitoringIcon
          key={`monitoring-icon-${MonitoringType.NotMonitored}`}
          type={MonitoringType.NotMonitored}
          withTooltip={false}
          visible={true}
          size={16}
        />,
      );
    }

    return result;
  }

  function getOverflowMenuItems():
    | Array<TableOverflowMenuItem<ExternalSystem>>
    | undefined {
    let menuItems: Array<TableOverflowMenuItem<ExternalSystem>> = [
      {
        label: t("overflowMenu.view"),
        action: (externalSystem) =>
          openDetailsInViewingModeAndRefresh(externalSystem),
      },
    ];

    if (hasPermission(Permission.UpdateExternalSystem)) {
      menuItems.push({
        label: t("overflowMenu.edit"),
        action: (externalSystem) =>
          openDetailsInEditingModeAndRefresh(externalSystem),
      });
    }
    if (hasPermission(Permission.DeleteExternalSystem)) {
      menuItems.push("divider");
      menuItems.push({
        label: t("overflowMenu.delete"),
        action: (externalSystem) =>
          externalSystemDetailsHook.deleteExternalSystem(externalSystem),
      });
    }

    return menuItems;
  }

  return (
    <div
      className="external-systems-container"
      data-testid="externalSystemsContainer"
    >
      <PageHeader
        title={t("pageTitle")}
        addActionPermission={Permission.CreateExternalSystem}
        isAddActionVisible={externalSystemsResponseIsSuccess}
        onAdd={openDetailsInCreationModeAndRefresh}
        navigationComponent={<HardwareNavigation />}
        filterComponent={
          externalSystemsResponseIsSuccess ? (
            <div className="external-system-filter-component-container">
              <ExternalSystemFilters />
            </div>
          ) : (
            <></>
          )
        }
      />
      {externalSystemsResponseIsLoading && <LoadingIndicator />}
      {externalSystemsResponseIsSuccess &&
        externalSystemsResponse &&
        !externalSystemsResponseIsLoading && (
          <Table
            className="external-systems-container"
            data={externalSystemsResponse.externalSystems.map<
              IRowItem<ExternalSystem>
            >((externalSystem) => ({ data: externalSystem }))}
            columns={columns}
            onItemClick={(externalSysem: ExternalSystem) =>
              openDetailsInViewingModeAndRefresh(externalSysem)
            }
            overflowMenuOptions={getOverflowMenuItems()}
            rowIdentifier={(externalSysem: ExternalSystem) => externalSysem.id!}
            initialOrderBy={
              externalSystemFilterHook.table.sortFromSessionStorage!.property
            }
            initialOrderDirection={
              externalSystemFilterHook.table.sortFromSessionStorage!.isAscending
                ? "asc"
                : "desc"
            }
            useBuiltInSorter={true}
            enablePagination={true}
            count={externalSystemsResponse.total}
            onPageChanged={externalSystemFilterHook.table.handleOnPageChanged}
            rowsPerPage={externalSystemFilterHook.table.currentRowsPerPage}
            page={externalSystemFilterHook.table.currentPage}
            onRowsPerPageChanged={
              externalSystemFilterHook.table.handleOnRowsPerPageChanged
            }
            onSortChanged={externalSystemFilterHook.table.handleOnSortChanged}
          />
        )}
      {externalSystemDetailsHook.isDetailsOpen && (
        <ExternalSystemDetailsPopup />
      )}
      <PopUp
        isOpen={externalSystemDetailsHook.isDeleteConfirmationPopupOpen}
        title={t("deleteSystemConfirmation.title", {
          externalSystemName:
            externalSystemDetailsHook.currentSelectedExternalSystem?.name,
        })}
        body={t("deleteSystemConfirmation.bodyText")}
        primaryButtonText={t("deleteSystemConfirmation.confirmButton")}
        secondaryButtonText={t("deleteSystemConfirmation.cancelButton")}
        handleOnClose={externalSystemDetailsHook.closeDeleteConfirmationPopup}
        secondaryButtonAction={
          externalSystemDetailsHook.closeDeleteConfirmationPopup
        }
        primaryButtonAction={() =>
          externalSystemDetailsHook.deleteConfirmationAction?.()
        }
        isLoadingPrimaryAction={externalSystemDetailsHook.mutationIsLoading}
      />

      <PopUp
        isOpen={externalSystemDetailsHook.isDeleteErrorPopupOpen}
        title={t("deleteSystemError.title", {
          externalSystemName:
            externalSystemDetailsHook.currentSelectedExternalSystem?.name,
        })}
        body={t("deleteSystemError.bodyText", {
          externalSystemName:
            externalSystemDetailsHook.currentSelectedExternalSystem?.name,
        })}
        primaryButtonText={t("deleteSystemError.confirmButton")}
        handleOnClose={externalSystemDetailsHook.closeDeleteErrorPopup}
        primaryButtonAction={externalSystemDetailsHook.closeDeleteErrorPopup}
      />
    </div>
  );
}

export default ExternalSystems;
