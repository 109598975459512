import MonitoringAlert from "components/monitoring/monitoring-alert.component";
import { MonitoringType } from "components/monitoring/monitoring-type";
import ExternalSystem from "features/external-system/domain/models/external-system";
import { ExternalSystemStatus } from "features/external-system/domain/models/external-system-status";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";

interface Props {
  externalSystem?: ExternalSystem;
}

const ExternalSystemMonitoringAlert = ({
  externalSystem,
}: Props): JSX.Element => {
  const { t } = useTranslation("monitoring");

  switch (externalSystem?.status) {
    case ExternalSystemStatus.offline:
      return (
        <MonitoringAlert
          text={t("messages.externalSystemOffline")}
          type={MonitoringType.ExternalSystemOffline}
          color={Constants.Colors.error}
        />
      );
    case ExternalSystemStatus.serviceOfflineOnly:
      return (
        <MonitoringAlert
          text={t("messages.externalSystemServiceOffline")}
          type={MonitoringType.ExternalSystemServiceOffline}
          color={Constants.Colors.brightOrange}
        />
      );
    case ExternalSystemStatus.passiveOfflineOnly: {
      const offlineChildSystem = externalSystem?.childExternalSystems?.find(
        (item) => item.status === ExternalSystemStatus.offline,
      );
      if (offlineChildSystem) {
        return (
          <MonitoringAlert
            text={t("messages.passiveExternalSystemOfflineWithName", {
              activeOrPassive: externalSystem.isActiveInCluster
                ? t("messages.active")
                : t("messages.passive"),
              externalSystemName: externalSystem.name || "",
            })}
            type={MonitoringType.PassiveExternalSystemOffline}
            color={Constants.Colors.brightOrange}
          />
        );
      } else return <></>;
    }
    default:
      return <></>;
  }
};

export default ExternalSystemMonitoringAlert;
