import { Link as LinkIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import CopyButton from "components/copy-button/copy-button.component";
import PopUp from "components/pop-up/pop-up.component";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import { useExternalSystemsContextProvider } from "features/external-system/context/external-systems-provider";
import useExternalSystemSettingsIoTEdgeClient from "features/external-system/hooks/external-system-settings-iot-edge-client-hook";
import { Trans, useTranslation } from "react-i18next";
import styles from "./external-system-settings-client.module.scss";

const ExternalSystemSettingsIoTEdgeClient = (): JSX.Element => {
  const { t } = useTranslation("externalSystem");

  const { externalSystemDetailsHook } = useExternalSystemsContextProvider();

  const {
    deviceId,
    ioTEdgeConnectionString,
    generateIoTEdgeConnectionStringIsLoading,
    isGenerateConfirmationOpen,
    defaultIoTEdgeConnectionString,
    generateIoTEdgeConnectionString,
    submitGenerateIoTEdgeConnectionString,
    closeGenerateConfirmationPopup,
  } = useExternalSystemSettingsIoTEdgeClient();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.infoItem}>
          <Typography variant="subtitle1">
            {t("details.settings.ioTEdgeDeviceIdLabel")}:
          </Typography>
          <Typography variant="subtitle1">
            {t("details.settings.ioTEdgeConnectionStringLabel")}:
          </Typography>
        </div>
        <div className={styles.infoItem}>
          <div className={styles.secret}>
            <Typography variant="subtitle1">{deviceId ?? "-"}</Typography>
            {deviceId !== undefined && <CopyButton contentToCopy={deviceId} />}
          </div>
          <div className={styles.secret}>
            <Typography variant="subtitle1">
              {ioTEdgeConnectionString}
            </Typography>
            {ioTEdgeConnectionString !== defaultIoTEdgeConnectionString && (
              <CopyButton contentToCopy={ioTEdgeConnectionString} />
            )}
          </div>
        </div>
      </div>
      <AutorisationWrapper
        atLeastOnePermissionOf={[Permission.CreateExternalSystem]}
      >
        <>
          {externalSystemDetailsHook.viewingMode === "viewing" &&
            (ioTEdgeConnectionString === defaultIoTEdgeConnectionString ? (
              <LoadingButton
                variant="outlined"
                onClick={generateIoTEdgeConnectionString}
                startIcon={<LinkIcon />}
                loading={generateIoTEdgeConnectionStringIsLoading}
                loadingPosition="start"
                className={styles.secretButton}
                data-testid="generate-secret-button"
              >
                {t("details.settings.clientSecret.generateButton")}
              </LoadingButton>
            ) : (
              <Typography variant="subtitle1">
                {t("details.settings.clientSecret.warningIoTEdge")}
              </Typography>
            ))}
          <PopUp
            isOpen={isGenerateConfirmationOpen}
            title={t("details.settings.clientSecret.confirmation.title")}
            body={
              <Trans
                t={t}
                i18nKey="details.settings.clientSecret.confirmation.bodyText"
              />
            }
            primaryButtonText={t(
              "details.settings.clientSecret.confirmation.confirmButton",
            )}
            secondaryButtonText={t(
              "details.settings.clientSecret.confirmation.cancelButton",
            )}
            handleOnClose={closeGenerateConfirmationPopup}
            secondaryButtonAction={closeGenerateConfirmationPopup}
            primaryButtonAction={submitGenerateIoTEdgeConnectionString}
          />
        </>
      </AutorisationWrapper>
    </>
  );
};

export default ExternalSystemSettingsIoTEdgeClient;
