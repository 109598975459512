import {
  IconButton,
  InputAdornment,
  InputLabelProps,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import "./device-details-popup-information.component.scss";
import { useTranslation } from "react-i18next";
import { ReactElement, useEffect } from "react";
import ViewingModeController from "components/viewModeController/viewing-mode-controller";
import { useFormContext } from "react-hook-form";
import SelectField from "components/formFields/select-field.component";
import { useDeviceDetailsHook } from "features/device/device-details/hooks";
import {
  useValidateDeviceExternalIdMutation,
  useValidateDeviceNameMutation,
} from "features/device/domain/reducers/device.reducer";
import { ApiResponse, isOkResponse } from "redux-base/create-api-utils";
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import CopyButton from "components/copy-button/copy-button.component";
import moment from "moment";
import Constants from "style/constants";
import { BatteryStatus } from "features/device/domain/models/battery-status";
import DeviceMonitoringAlert from "./components/device-monitoring-alert";
import { useReadSystemsQuery } from "features/external-system/domain/reducers/external-system.reducer";
import { ReadExternalSystemsQuery } from "features/external-system/domain/models/read-external-systems-query";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import { useAppDispatch } from "redux-base/store";
import { DeviceType } from "features/device/models/device-type";
import { ExternalSystemType } from "features/external-system/domain/models/external-system-type";
import ExternalSystemFilter from "features/external-system/domain/models/external-system-filter";
import { FilterValueType } from "features/external-system/domain/models/external-system-filter-value";

function DeviceDetailsPopupInformation(): ReactElement {
  const { t } = useTranslation("deviceDetails");
  const dispatch = useAppDispatch();

  const {
    getValues,
    formState: { errors },
    control,
    trigger,
    watch,
  } = useFormContext();
  const watchExternalSystemId = watch("externalSystemId");

  const [validateDeviceName] = useValidateDeviceNameMutation();
  const [validateDeviceExternalId] = useValidateDeviceExternalIdMutation();

  const registerOptions = {
    name: {
      required: t("info.requiredHelperText"),
      validate: validateName,
    },
    externalId: {
      required: t("info.requiredHelperText"),
      validate: validateExternalId,
    },
    externalSystemId: {
      required: t("info.requiredHelperText"),
    },
  };

  const {
    currentViewingMode,
    currentDevice,
    currentDeviceType,
    generateExternalId,
  } = useDeviceDetailsHook();

  const { data: externalSystemsResponse, error: externalSystemsResponseError } =
    useReadSystemsQuery(
      currentDeviceType === DeviceType.NFC ||
        currentDeviceType === DeviceType.ESPA
        ? ({
            filters: [
              {
                filterValueType: FilterValueType.Type,
                values: [getExternalSystemFilterType(currentDeviceType)],
              } as ExternalSystemFilter,
            ],
          } as ReadExternalSystemsQuery)
        : ({} as ReadExternalSystemsQuery),
    );
  useEffect(() => {
    if (externalSystemsResponseError) {
      dispatch(
        setErrorMessage({
          error: externalSystemsResponseError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalSystemsResponseError]);

  const inputLabelProps: Partial<InputLabelProps> =
    currentDevice?.importDate != null ? { shrink: true } : {};

  function getExternalSystemFilterType(
    deviceType: DeviceType,
  ): string | undefined {
    switch (deviceType) {
      case DeviceType.NFC:
        return ExternalSystemType.NFC.toString();
      case DeviceType.ESPA:
        return ExternalSystemType.ESPA.toString();
    }
    return undefined;
  }

  async function validateName(): Promise<boolean | string> {
    if (currentDevice?.importDate) {
      return true;
    }

    const id = getValues("id");
    const name = getValues("name").trim();
    const externalSystemId = getValues("externalSystemId");

    let result = (await validateDeviceName({
      id: currentViewingMode === "editing" ? id : undefined,
      deviceName: name,
      externalSystemId: externalSystemId !== "" ? externalSystemId : undefined,
    })) as ApiResponse<void>;

    if (isOkResponse(result)) {
      return true;
    }

    return t("info.uniqueNameHelperText");
  }

  async function validateExternalId(): Promise<boolean | string> {
    const id = getValues("id");
    const externalId = getValues("externalId").trim();
    const externalSystemId = getValues("externalSystemId");

    let result = (await validateDeviceExternalId({
      id: currentViewingMode === "editing" ? id : undefined,
      externalId: externalId,
      externalSystemId: externalSystemId !== "" ? externalSystemId : undefined,
    })) as ApiResponse<void>;
    if (
      (currentDevice?.externalId === externalId &&
        currentDevice?.externalSystemId === externalSystemId) ||
      isOkResponse(result)
    ) {
      return true;
    }

    return t("info.uniqueExternalIdHelperText");
  }

  useEffect(() => {
    if (currentViewingMode === "creation" || currentViewingMode === "editing") {
      const currentNameValue = getValues("name");
      if (currentNameValue && currentNameValue.length > 0) {
        trigger("name");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchExternalSystemId, currentViewingMode]);

  function showField(field: string): boolean {
    if (currentViewingMode !== "viewing") {
      return true;
    }

    const value = getValues(field)?.toString();
    return value && value.trim() !== "";
  }

  const showMonitoringContainer =
    currentDevice?.isOutdated ||
    currentDevice?.deviceMonitoring?.isOffline ||
    currentDevice?.deviceMonitoring?.batteryStatus === BatteryStatus.Critical ||
    currentDevice?.deviceMonitoring?.batteryStatus === BatteryStatus.Low;

  return (
    <div className="device-details-information-container">
      <Typography
        variant="h2"
        className="device-title"
        data-testid="devicePopupTitle"
      >
        {currentViewingMode === "creation" && t("createDeviceTitle")}
        {currentViewingMode === "editing" && t("editDeviceTitle")}
        {currentViewingMode === "viewing" && currentDevice?.name}
      </Typography>
      {showMonitoringContainer && (
        <div className="monitoring-container">
          {currentDevice?.isOutdated && <DeviceMonitoringAlert.Outdated />}
          {currentDevice?.deviceMonitoring?.isOffline && (
            <DeviceMonitoringAlert.Offline
              lastChangedOn={
                currentDevice.deviceMonitoring.lastMessageReceivedAt
              }
            />
          )}
          {currentDevice?.deviceMonitoring?.batteryStatus ===
            BatteryStatus.Critical && (
            <DeviceMonitoringAlert.BatteryCritical
              lastChangedOn={
                currentDevice.deviceMonitoring.batteryStatusChangedAt
              }
              message={currentDevice.deviceMonitoring.message}
            />
          )}
          {currentDevice?.deviceMonitoring?.batteryStatus ===
            BatteryStatus.Low && (
            <DeviceMonitoringAlert.BatteryLow
              lastChangedOn={
                currentDevice.deviceMonitoring.batteryStatusChangedAt
              }
              message={currentDevice.deviceMonitoring.message}
            />
          )}
        </div>
      )}
      <div className="information-part-container">
        <ViewingModeController
          viewingMode={currentViewingMode}
          name="name"
          control={control}
          rules={registerOptions.name}
          label={t("info.nameLabel")}
          render={({ field }) => (
            <TextField
              {...field}
              InputProps={{
                disabled: currentDevice?.importDate != null,
              }}
              inputProps={{
                "data-testid": "nameInput",
                maxLength: 255,
              }}
              id="nameInput"
              label={t("info.nameLabel")}
              variant="outlined"
              error={!!errors.name}
              helperText={errors.name?.message?.toString()}
              className="form-field"
            />
          )}
        />
      </div>
      {showField("note") && (
        <div
          className="information-part-container"
          data-testid="devicePopupNoteContainer"
        >
          <ViewingModeController
            viewingMode={currentViewingMode}
            name="note"
            control={control}
            label={t("info.noteLabel")}
            hideOnViewingWhenEmpty
            render={({ field }) => (
              <TextField
                {...field}
                inputProps={{
                  "data-testid": "noteInput",
                  maxLength: 255,
                }}
                id="noteInput"
                label={t("info.noteLabel")}
                variant="outlined"
                error={!!errors.note}
                helperText={errors.note?.message?.toString()}
                multiline
                minRows={8}
                maxRows={12}
                className="form-field"
              />
            )}
          />
        </div>
      )}
      <div
        className="information-part-container"
        data-testid="devicePopupExternalIdContainer"
      >
        <ViewingModeController
          viewingMode={currentViewingMode}
          name="externalId"
          control={control}
          rules={registerOptions.externalId}
          label={t("info.externalId")}
          render={({ field }) => (
            <TextField
              {...field}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        generateExternalId();
                        trigger("externalId");
                      }}
                      edge="end"
                    >
                      {currentDevice?.importDate == null && (
                        <AutorenewRoundedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                disabled: currentDevice?.importDate != null,
              }}
              variant="outlined"
              id="externalIdInput"
              label={t("info.externalId")}
              error={!!errors.externalId}
              helperText={errors.externalId?.message?.toString()}
              className="form-field"
            />
          )}
          display={
            <div className="functional-id-container">
              <Typography
                variant="subtitle1"
                data-testid="viewingModeControllerDisplayValue"
              >
                {currentDevice?.externalId}
              </Typography>
              <CopyButton contentToCopy={currentDevice?.externalId} />
            </div>
          }
        />
      </div>
      <div
        className="information-part-container"
        data-testid="devicePopupExternalSystemContainer"
      >
        <ViewingModeController
          viewingMode={currentViewingMode}
          name="externalSystemId"
          rules={registerOptions.externalSystemId}
          control={control}
          displayValue={
            externalSystemsResponse?.externalSystems.find(
              (system) => system.id === currentDevice?.externalSystemId,
            )?.name ?? "Not found!"
          }
          label={t("info.externalSystemLabel")}
          render={({ field }) => (
            <SelectField
              {...field}
              disabled={
                currentDevice?.importDate != null ||
                currentViewingMode === "editing"
              }
              inputProps={{
                "data-testid": "externalSystemInput",
                disabled:
                  currentDevice?.importDate != null ||
                  currentViewingMode === "editing",
              }}
              id="externalSystemInput"
              label={t("info.externalSystemLabel")}
              variant="outlined"
              error={!!errors.externalSystemId}
              helperText={errors?.externalSystemId?.message?.toString()}
              className="form-field"
              data-testid="externalSystemSelect"
              onClick={() => trigger("externalId")}
            >
              {externalSystemsResponse?.externalSystems.map((system) => (
                <MenuItem
                  data-testid={`externalSystemOption${system.id}`}
                  key={`system-${system.id}`}
                  value={system.id}
                >
                  {system.name}
                </MenuItem>
              ))}
            </SelectField>
          )}
        />
      </div>
      {currentViewingMode !== "creation" &&
        currentDevice?.source !== undefined &&
        currentDevice?.source !== "" && (
          <div
            className="information-part-container"
            data-testid="devicePopupSourceContainer"
          >
            <ViewingModeController
              viewingMode={currentViewingMode}
              name="source"
              control={control}
              label={t("info.sourceLabel")}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputProps={{
                    disabled: currentDevice?.importDate != null,
                  }}
                  inputProps={{
                    "data-testid": "sourceInput",
                    maxLength: 255,
                  }}
                  id="sourceInput"
                  label={t("info.sourceLabel")}
                  variant="outlined"
                  error={!!errors.source}
                  helperText={errors.source?.message?.toString()}
                  className="form-field"
                />
              )}
            />
          </div>
        )}
      {currentDeviceType !== DeviceType.NFC && (
        <div
          className="information-part-container"
          data-testid="devicePopupAddressContainer"
        >
          <ViewingModeController
            viewingMode={currentViewingMode}
            name="address"
            control={control}
            label={t("info.addressLabel")}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={inputLabelProps}
                InputProps={{
                  disabled: currentDevice?.importDate != null,
                }}
                inputProps={{
                  "data-testid": "addressInput",
                  maxLength: 255,
                }}
                id="addressInput"
                label={t("info.addressLabel")}
                variant="outlined"
                error={!!errors.address}
                helperText={errors.address?.message?.toString()}
                className="form-field"
              />
            )}
          />
        </div>
      )}
      {currentDevice?.deviceMonitoring?.lastMessageReceivedAt && (
        <div className="information-part-container">
          <Typography variant="h5" sx={{ color: Constants.Colors.onSurface }}>
            {t("info.status")}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: Constants.Colors.onSurface }}
          >
            {`${t("info.lastSeenAt")} ${moment(
              currentDevice.deviceMonitoring.lastMessageReceivedAt,
            )
              .format("DD-MM-YYYY HH:mm")
              .toString()}`}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default DeviceDetailsPopupInformation;
