import { CommentOutlined, LinkOutlined } from "@mui/icons-material";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import PageHeader from "components/page-header/page-header";
import { ColumnDefinition } from "components/table/columnDefinition";
import Table, { IRowItem, TableOverflowMenuItem } from "components/table/table";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import Device from "features/device/domain/models/device";
import ReadDevicesCommand from "features/device/domain/models/read-devices-command";
import {
  useLazyReadDevicesQuery,
  useLazyReadFilterValuesQuery,
} from "features/device/domain/reducers/device.reducer";
import DeviceDetailsPopup from "features/device/device-details/views/device-details-popup.component";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import HardwareNavigation from "features/hardware/views/hardware-navigation";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "redux-base/store";
import { NestedKeyof } from "utils/nested-keyof-utils";
import "./devices.component.scss";
import { FilterValueType } from "features/device/models/device-filter-value";
import DeviceFilters from "features/device/views/device-filters";
import { ViewingMode } from "utils/viewing-utils";
import { useAuth } from "features/authentication/providers/authentication.provider";
import PopUp from "components/pop-up/pop-up.component";
import { DeviceDetailsProvider } from "features/device/device-details/providers/device-details-provider/device-details.provider";
import { useDevices } from "features/device/devices/hooks";
import useTableHook from "hooks/table-hook";
import DeviceFilter from "features/device/models/device-filter";
import MonitoringIcon from "components/monitoring/monitoring-icon.component";
import { MonitoringType } from "components/monitoring/monitoring-type";
import { BatteryStatus } from "features/device/domain/models/battery-status";
import DeviceFilterValues from "features/device/models/device-filter-values";
import Constants from "style/constants";
import SendTestEventPopup from "features/device/send-test-event/views/send-test-event-popup.component";
import { useSendTestEventContextProvider } from "features/device/send-test-event/context/send-test-event-provider";
import { ExternalSystemType } from "features/external-system/domain/models/external-system-type";

function Devices(): ReactElement {
  const { hasPermission } = useAuth();
  const { t } = useTranslation("devices");
  const dispatch = useAppDispatch();

  const [
    triggerReadDevicesQuery,
    {
      data: readDevicesData,
      isSuccess: readDevicesIsSuccess,
      isLoading: readDevicesIsLoading,
      isFetching: readDevicesIsFetching,
      error: readDevicesError,
    },
  ] = useLazyReadDevicesQuery();
  const [
    triggerReadFilterValuesQuery,
    {
      data: readFilterValuesData,
      error: readFilterValuesError,
      isLoading: readFilterValuesIsLoading,
      isSuccess: readFilterValuesIsSucces,
    },
  ] = useLazyReadFilterValuesQuery();

  const [detailsViewingMode, setDetailsViewingMode] =
    useState<ViewingMode>("none");
  const [currentDeviceId, setCurrentDeviceId] = useState<string>();

  const {
    currentPagination: [currentPagination, setCurrentPagination],
    currentSearchQuery: [currentSearchQuery],
    currentPage,
    currentRowsPerPage,
    setCurrentPage,
    handleOnPageChanged,
    handleOnRowsPerPageChanged,
    handleOnSearchChanged,
  } = useTableHook();

  const {
    confirmDeleteDevice,
    deleteDevice,
    closeDeleteConfirmationPopup,
    closeDeleteErrorPopup,
    openDeviceDetailsPopup,
    isDeleteConfirmationPopupOpen,
    isDeleteErrorPopupOpen,
    isDeviceDetailsPopupOpen,
    deviceFiltersFromSessionStorage,
    setDeviceFiltersFromSessionStorage,
    sortFromSessionStorage,
    setSortFromSessionStorage,
    searchBarFromSessionStorage,
    setSearchBarFromSessionStorage,
  } = useDevices();

  const { openSendTestEventPopup, isSendTestEventPopupOpen } =
    useSendTestEventContextProvider();

  const columns: ColumnDefinition<Device, NestedKeyof<Device>>[] = [
    { key: "name", label: t("devicesTable.column.name") },
    {
      key: "type",
      label: t("devicesTable.column.type"),
      renderCustomContentProvider: (device) => (
        <>{t(`deviceTypes.${device.type}`)}</>
      ),
      disableSort: true,
    },
    {
      key: "externalSystem.name",
      label: t("devicesTable.column.externalSystem"),
    },
    {
      key: "source",
      label: t("devicesTable.column.source"),
      disableSort: true,
      tableCellProps: {
        align: "left",
        width: "200px",
      },
    },
    {
      key: "isOutdated",
      label: "",
      renderCustomContentProvider: (device) => (
        <div className="icon-row">{getIcons(device)}</div>
      ),
      disableSort: true,
      tableCellProps: {
        align: "left",
        width: "40px",
      },
    },
  ];

  const getIcons = (device: Device): ReactElement[] => {
    const result: ReactElement[] = [];
    if (!readDevicesData) {
      return result;
    }

    if (readDevicesData.devices.find((x) => x.note)) {
      result.push(
        <CommentOutlined
          data-testid="noteIcon"
          key="note-icon"
          sx={{
            width: 16,
            height: 16,
            color: device.note
              ? Constants.Colors.onSurfaceVariant
              : "transparent",
          }}
        />,
      );
    }

    const monitoringIcon = determineMonitoringIcon(device);
    if (monitoringIcon) {
      result.push(monitoringIcon);
    }

    if (readDevicesData.devices.find((x) => x.organisationUnitId)) {
      result.push(
        <LinkOutlined
          data-testid="linkIcon"
          key="link-icon"
          sx={{
            width: 16,
            height: 16,
            color: device.organisationUnitId
              ? Constants.Colors.onPrimaryContainer
              : "transparent",
          }}
        />,
      );
    }

    return result;
  };

  const determineMonitoringIcon = (
    device: Device,
  ): ReactElement | undefined => {
    if (device.isOutdated) {
      return (
        <MonitoringIcon
          key={`monitoring-icon-${MonitoringType.OutDated}`}
          type={MonitoringType.OutDated}
          withTooltip={true}
          visible={true}
          size={16}
        />
      );
    } else if (!device.deviceMonitoring) {
      return (
        <MonitoringIcon
          key={`monitoring-icon-${MonitoringType.NotMonitored}`}
          type={MonitoringType.NotMonitored}
          withTooltip={true}
          visible={true}
          size={16}
        />
      );
    } else if (device.deviceMonitoring?.isOffline) {
      return (
        <MonitoringIcon
          key={`monitoring-icon-${MonitoringType.Offline}`}
          type={MonitoringType.Offline}
          withTooltip={true}
          visible={true}
          size={16}
        />
      );
    } else if (
      device.deviceMonitoring?.batteryStatus === BatteryStatus.Critical &&
      !(device.deviceMonitoring?.isOffline ?? false)
    ) {
      return (
        <MonitoringIcon
          key={`monitoring-icon-${MonitoringType.BatteryCritical}`}
          type={MonitoringType.BatteryCritical}
          withTooltip={true}
          visible={true}
          size={16}
        />
      );
    } else if (
      device.deviceMonitoring?.batteryStatus === BatteryStatus.Low &&
      !(device.deviceMonitoring?.isOffline ?? false)
    ) {
      return (
        <MonitoringIcon
          key={`monitoring-icon-${MonitoringType.BatteryLow}`}
          type={MonitoringType.BatteryLow}
          withTooltip={true}
          visible={true}
          size={16}
        />
      );
    } else if (
      device.deviceMonitoring != null &&
      !device.deviceMonitoring.isOffline
    ) {
      return (
        <MonitoringIcon
          key={`monitoring-icon-${MonitoringType.Online}`}
          type={MonitoringType.Online}
          withTooltip={true}
          visible={true}
          size={16}
        />
      );
    } else if (
      readDevicesData?.devices.find((x) => x.deviceMonitoring || x.isOutdated)
    ) {
      return (
        <MonitoringIcon
          key={`monitoring-icon-filler`}
          type={MonitoringType.BatteryLow}
          withTooltip={false}
          visible={false}
          size={16}
        />
      );
    }
  };

  const openDetails = (
    viewingMode: ViewingMode,
    device: Device | undefined = undefined,
  ) => {
    triggerReadDevices();
    setCurrentDeviceId(device?.id);
    openDeviceDetailsPopup(false);
    setDetailsViewingMode(viewingMode);
  };

  const openSendTestEvent = (device: Device) => {
    openSendTestEventPopup(device);
  };

  const getOverflowMenuItems = ():
    | Array<TableOverflowMenuItem<Device>>
    | undefined => {
    let menuItems: Array<TableOverflowMenuItem<Device>> = [
      {
        label: t("devicesTable.action.view"),
        action: (device) => openDetails("viewing", device),
      },
    ];

    if (hasPermission(Permission.UpdateDevice)) {
      menuItems.push({
        label: t("devicesTable.action.edit"),
        action: (device) => openDetails("editing", device),
      });
    }
    if (hasPermission(Permission.SendTestEvent)) {
      menuItems.push({
        label: t("devicesTable.action.sendTestEvent"),
        action: (device) => openSendTestEvent(device),
        isVisible: (device) =>
          device.organisationUnitId !== null &&
          (device.externalSystem.type === ExternalSystemType.IoTEdge ||
            device.externalSystem.type === ExternalSystemType.IoTEdgeCluster),
      });
    }
    if (hasPermission(Permission.DeleteDevice)) {
      menuItems.push("divider");
      menuItems.push({
        label: t("devicesTable.action.delete"),
        action: (device) => {
          setCurrentDeviceId(device.id);
          confirmDeleteDevice(device);
        },
      });
    }

    return menuItems;
  };

  const handleOnSortChanged = (
    property: NestedKeyof<Device>,
    isAscending: boolean,
  ) => {
    setSortFromSessionStorage({
      isAscending: isAscending,
      property: property,
    });

    setCurrentPage(0);
    setCurrentPagination({
      skip: 0,
      take: currentRowsPerPage,
    });
  };

  const handleOnOptionsSelected = (
    filterValueType: FilterValueType,
    keys: string[],
  ) => {
    const newFilters = [...deviceFiltersFromSessionStorage.filterValues];
    const indexOfFilterWithSameFilterValueType = newFilters.findIndex(
      (filter) => filter.filterValueType === filterValueType,
    );
    const deviceFilter: DeviceFilter = {
      filterValueType: filterValueType,
      values: keys,
    };

    if (indexOfFilterWithSameFilterValueType !== -1) {
      newFilters[indexOfFilterWithSameFilterValueType] = deviceFilter;
    } else {
      newFilters.push(deviceFilter);
    }

    handleOnFiltersChanged({
      ...deviceFiltersFromSessionStorage,
      filterValues: newFilters,
    });
  };

  const handleOnMonitoringFilterSelected = (
    selected: boolean,
    type: MonitoringType,
  ) => {
    let newFilters = [
      ...deviceFiltersFromSessionStorage.monitoringFilterValues,
    ];

    if (selected) {
      newFilters.push({ monitoringType: type, count: 0 });
    } else {
      const index = newFilters.findIndex(
        (filter) => filter.monitoringType === type,
      );
      newFilters.splice(index, 1);
    }

    handleOnFiltersChanged({
      ...deviceFiltersFromSessionStorage,
      monitoringFilterValues: newFilters,
    });
  };

  const handleOnFiltersChanged = (deviceFilters: DeviceFilterValues) => {
    setDeviceFiltersFromSessionStorage(deviceFilters);
    setCurrentPage(0);
    handleOnPageChanged(0);
  };

  const clearFilters = () => {
    setDeviceFiltersFromSessionStorage({
      filterValues: [],
      monitoringFilterValues: [],
    });
  };

  const triggerReadDevices = () => {
    const readDevicesQuery = {
      pagination: currentPagination,
      sort: sortFromSessionStorage,
      filters: deviceFiltersFromSessionStorage,
      searchQuery: searchBarFromSessionStorage,
    } as ReadDevicesCommand;

    triggerReadDevicesQuery(readDevicesQuery);
    triggerReadFilterValuesQuery();
  };

  useEffect(() => {
    if (readDevicesError) {
      dispatch(
        setErrorMessage({
          error: readDevicesError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readDevicesError]);

  useEffect(() => {
    if (readFilterValuesError) {
      dispatch(
        setErrorMessage({
          error: readFilterValuesError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readFilterValuesError]);

  useEffect(() => {
    triggerReadDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sortFromSessionStorage,
    currentPagination,
    deviceFiltersFromSessionStorage,
    currentSearchQuery,
  ]);

  const isValidFilter = (filter: DeviceFilter): boolean => {
    if (!readFilterValuesData) {
      return false;
    }

    const filterValues = readFilterValuesData.filterValues
      .find(
        (availableFilter) =>
          availableFilter.filterValueType === filter.filterValueType,
      )
      ?.values.map((x) => x.key);

    if (!filterValues) {
      return false;
    }

    return (
      filterValues &&
      filter.values.every((value) => filterValues.includes(value))
    );
  };

  useEffect(() => {
    if (
      deviceFiltersFromSessionStorage?.filterValues &&
      deviceFiltersFromSessionStorage.filterValues.length > 0 &&
      readFilterValuesIsSucces &&
      readFilterValuesData
    ) {
      const invalidFilters =
        deviceFiltersFromSessionStorage.filterValues.filter(
          (filter) => !isValidFilter(filter),
        );

      if (invalidFilters.length > 0) {
        setDeviceFiltersFromSessionStorage({
          filterValues: deviceFiltersFromSessionStorage.filterValues.filter(
            (filter) => !invalidFilters.includes(filter),
          ),
          monitoringFilterValues: [],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readFilterValuesData]);

  return (
    <AutorisationWrapper atLeastOnePermissionOf={[Permission.ReadDevice]}>
      <div className="devices-container">
        <PageHeader
          title={t("pageHeaderTitle")}
          addActionPermission={Permission.CreateDevice}
          isAddActionVisible={readDevicesIsSuccess}
          onAdd={() => openDetails("creation")}
          navigationComponent={<HardwareNavigation />}
          filterComponent={
            readFilterValuesIsSucces && readFilterValuesData ? (
              <DeviceFilters
                onSearchValueChanged={(value: string) => {
                  handleOnSearchChanged(value);
                  setSearchBarFromSessionStorage(value);
                }}
                activeFilters={
                  deviceFiltersFromSessionStorage?.filterValues ?? []
                }
                filterValues={readFilterValuesData.filterValues}
                activeMonitoringFilters={
                  deviceFiltersFromSessionStorage.monitoringFilterValues ?? []
                }
                monitoringValues={readFilterValuesData.monitoringFilterValues}
                onOptionsSelected={handleOnOptionsSelected}
                onMonitoringFilterSelected={handleOnMonitoringFilterSelected}
                clearFilters={clearFilters}
                searchBarValue={searchBarFromSessionStorage}
                refresh={() => {
                  triggerReadDevices();
                  triggerReadFilterValuesQuery();
                }}
              />
            ) : undefined
          }
        />
        {(readDevicesIsLoading ||
          readDevicesIsFetching ||
          readFilterValuesIsLoading) && <LoadingIndicator />}
        {readDevicesIsSuccess && readDevicesData && (
          <Table
            data={readDevicesData.devices.map<IRowItem<Device>>((device) => ({
              data: device,
            }))}
            columns={columns}
            overflowMenuOptions={getOverflowMenuItems()}
            onItemClick={(item: Device) => openDetails("viewing", item)}
            enablePagination={true}
            count={readDevicesData.total}
            onPageChanged={handleOnPageChanged}
            rowsPerPage={currentRowsPerPage}
            onSortChanged={handleOnSortChanged}
            page={currentPage}
            onRowsPerPageChanged={handleOnRowsPerPageChanged}
            rowIdentifier={(item: Device) => item.id!}
            initialOrderBy={sortFromSessionStorage.property}
            initialOrderDirection={
              sortFromSessionStorage.isAscending ? "asc" : "desc"
            }
          />
        )}
        {isDeviceDetailsPopupOpen && (
          <DeviceDetailsProvider
            deviceId={currentDeviceId}
            initialViewingMode={detailsViewingMode}
          >
            <DeviceDetailsPopup isSendTestEventAvailable={true} />
          </DeviceDetailsProvider>
        )}
        {isSendTestEventPopupOpen && <SendTestEventPopup />}
        <PopUp
          isOpen={isDeleteConfirmationPopupOpen}
          title={t("deleteSystemConfirmation.title", {
            deviceName: readDevicesData?.devices.find(
              (x) => x.id === currentDeviceId,
            )?.name,
          })}
          body={t("deleteSystemConfirmation.bodyText")}
          primaryButtonText={t("deleteSystemConfirmation.confirmButton")}
          secondaryButtonText={t("deleteSystemConfirmation.cancelButton")}
          handleOnClose={() => closeDeleteConfirmationPopup()}
          secondaryButtonAction={() => closeDeleteConfirmationPopup()}
          primaryButtonAction={() => deleteDevice()}
        />

        <PopUp
          isOpen={isDeleteErrorPopupOpen}
          title={t("deleteSystemError.title", {
            deviceName: readDevicesData?.devices.find(
              (x) => x.id === currentDeviceId,
            )?.name,
          })}
          body={t("deleteSystemError.bodyText")}
          primaryButtonText={t("deleteSystemError.confirmButton")}
          handleOnClose={() => closeDeleteErrorPopup()}
          primaryButtonAction={() => closeDeleteErrorPopup()}
        />
      </div>
    </AutorisationWrapper>
  );
}

export default Devices;
