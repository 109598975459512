import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useExternalSystemsContextProvider } from "features/external-system/context/external-systems-provider";
import useExternalSystemValidation from "features/external-system/hooks/external-system-validation-hook";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import styles from "./external-system-settings-kadex.module.scss";

const ExternalSystemSettingsKadex = (): JSX.Element => {
  const { t } = useTranslation("externalSystem");

  const { externalSystemDetailsHook } = useExternalSystemsContextProvider();

  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { validateIpAddress } = useExternalSystemValidation();
  const [showPassword, setShowPassword] = useState(false);

  const registerOptions = {
    kadexIpAddress: {
      validate: validateIpAddress,
    },
  };

  const isViewing = externalSystemDetailsHook.viewingMode === "viewing";
  const currentSystem = externalSystemDetailsHook.currentSelectedExternalSystem;

  const hasNoIpAddress =
    !currentSystem?.kadexIpAddress || currentSystem?.kadexIpAddress === "";
  const hasNoUsername =
    !externalSystemDetailsHook.currentSelectedExternalSystem?.kadexUsername ||
    externalSystemDetailsHook.currentSelectedExternalSystem?.kadexUsername ===
      "";
  const hasNoPassword =
    !externalSystemDetailsHook.currentSelectedExternalSystem?.kadexPassword ||
    externalSystemDetailsHook.currentSelectedExternalSystem?.kadexPassword ===
      "";

  const hiddenPassword = "**********";

  return (
    <>
      {isViewing && (
        <div className={`${styles.container} ${styles.viewing}`}>
          <Typography variant="subtitle1" data-testid="ipAddressLabel">
            {t("details.settings.kadex.ipAddress")}:{" "}
            {hasNoIpAddress
              ? t(`details.settings.kadex.noIpAddressDefined`)
              : currentSystem?.kadexIpAddress}
          </Typography>
          <Typography variant="subtitle1" data-testid="usernameLabel">
            {t("details.settings.kadex.username")}:{" "}
            {hasNoUsername
              ? t(`details.settings.kadex.noUsernameDefined`)
              : currentSystem?.kadexUsername}
          </Typography>
          <Typography variant="subtitle1" data-testid="passwordLabel">
            {t("details.settings.kadex.password")}:{" "}
            {hasNoPassword
              ? t(`details.settings.kadex.noPasswordDefined`)
              : hiddenPassword}
          </Typography>
        </div>
      )}
      {!isViewing && (
        <div className={styles.container}>
          <Controller
            name={"kadexIpAddress"}
            control={control}
            rules={registerOptions.kadexIpAddress}
            render={({ field }) => (
              <TextField
                {...field}
                inputProps={{
                  "data-testid": `kadexIpAddress`,
                }}
                id="kadexIpAddress"
                label={t("details.settings.kadex.ipAddress")}
                variant="outlined"
                error={!!errors.kadexIpAddress}
                helperText={errors.kadexIpAddress?.message?.toString()}
                className={styles.textfield}
              />
            )}
          />
          <Controller
            name={"kadexUsername"}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                inputProps={{
                  "data-testid": `kadexUsername`,
                }}
                id="kadexUsername"
                label={t("details.settings.kadex.username")}
                variant="outlined"
                className={styles.textfield}
              />
            )}
          />
          <Controller
            name={"kadexPassword"}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type={showPassword ? "text" : "password"}
                inputProps={{
                  "data-testid": `kadexPassword`,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                id="kadexPassword"
                label={t("details.settings.kadex.password")}
                variant="outlined"
                className={styles.textfield}
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default ExternalSystemSettingsKadex;
