import { Dialog } from "@mui/material";
import DetailsPopUpContent from "components/details-pop-up-content/details-pop-up-content.component";
import PopUp from "components/pop-up/pop-up.component";
import { useExternalSystemsContextProvider } from "features/external-system/context/external-systems-provider";
import ExternalSystem from "features/external-system/domain/models/external-system";
import { ExternalSystemStatus } from "features/external-system/domain/models/external-system-status";
import { FieldValues, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ExternalSystemDetailsPopupHeader from "./external-system-details-popup-header";
import ExternalSystemInformation from "./external-system-information.component";
import ExternalSystemSettings from "./external-system-settings/external-system-settings";

const ExternalSystemDetailsPopup = (): JSX.Element => {
  const { t } = useTranslation("externalSystem");

  const { externalSystemDetailsHook } = useExternalSystemsContextProvider();

  const submitForm = (fieldValues: FieldValues) => {
    const externalSystem: ExternalSystem = {
      id: externalSystemDetailsHook.currentSelectedExternalSystem?.id,
      type: fieldValues.type,
      name: fieldValues.name?.trim(),
      ipAddress: fieldValues.ipAddress?.trim(),
      note: fieldValues.note?.trim(),
      organisationUnitId: fieldValues.organisationUnitId,
      lastCommunicationDate:
        externalSystemDetailsHook.currentSelectedExternalSystem
          ?.lastCommunicationDate,
      shouldCreateDevicesAutomatically:
        fieldValues.shouldCreateDevicesAutomatically,
      offlinePortalPassword: fieldValues.offlinePortalPassword,
      offlinePortalUsername: fieldValues.offlinePortalUsername,
      kadexIpAddress: fieldValues.kadexIpAddress,
      kadexUsername: fieldValues.kadexUsername,
      kadexPassword: fieldValues.kadexPassword,
      childExternalSystems: fieldValues.childExternalSystems,
      status: ExternalSystemStatus.notMonitored,
    };

    externalSystemDetailsHook.submitExternalSystem(externalSystem);
  };

  const popUpStyle = {
    style: {
      height: "960px",
      width: "1227px",
      maxWidth: "1227px",
      paddingBottom: "0px",
    },
  };

  return (
    <>
      <Dialog
        open={externalSystemDetailsHook.isDetailsOpen}
        onClose={() =>
          externalSystemDetailsHook.closeDetails(
            externalSystemDetailsHook.form.formState.isDirty,
          )
        }
        PaperProps={popUpStyle}
      >
        <FormProvider {...externalSystemDetailsHook.form}>
          <DetailsPopUpContent
            onSubmit={externalSystemDetailsHook.form.handleSubmit(submitForm)}
            leftContent={<ExternalSystemInformation />}
            rightContent={<ExternalSystemSettings />}
            header={<ExternalSystemDetailsPopupHeader />}
          />
        </FormProvider>
      </Dialog>
      <PopUp
        isOpen={externalSystemDetailsHook.isIpAddressPopupOpen}
        title={t("details.ipAddressPopup.title")}
        body={t("details.ipAddressPopup.bodyText")}
        primaryButtonText={t("details.ipAddressPopup.confirmButton")}
        secondaryButtonText={t("details.ipAddressPopup.cancelButton")}
        handleOnClose={externalSystemDetailsHook.closeIpAddressPopup}
        secondaryButtonAction={externalSystemDetailsHook.closeIpAddressPopup}
        primaryButtonAction={() =>
          externalSystemDetailsHook.ipAddressConfirmationAction?.()
        }
      />
    </>
  );
};

export default ExternalSystemDetailsPopup;
